.AboutPage .BanContSec .textBlack h1 {text-transform: uppercase;}
.aboutIntrosec{text-align: center; padding:80px 0;  background: #eff8fa;}
.aboutIntrosec h4{padding: 0; margin: 0 0 30px;}
.aboutJourneyBottom .textBlack.tbTitle{text-align: center;  margin: -140px 0 130px;}
.aboutJourneyBottom .textBlack.tbTitle h2{ padding: 0;  margin: 0;  color: #12adc5;  font-size: 120px;   line-height: 130px;  font-weight: 900;  letter-spacing: -6px;}
.aboutJourneyBottom .textBlack.tbTitle h2:before {   content: attr(title);   position: absolute;   -webkit-text-stroke: 40px #fff;  top: 0;  z-index: -1;}

.aboutJourneyBottom .ourjournyContSlide .slick-initialized .slick-slide {
    display: block;
    width:50% !important;
    opacity: 0.5 !important;
    margin: 0 0 50px;
}
.ourjournyImgSlide{ width: 460px;  margin: 0 auto;   text-align: center;    position: relative;   top: 290px;   padding: 0 0 60px;  
position: relative;}

.ourjournyImgSlide::before{content: ''; position: absolute; left: 0; width:38%; height: 2px; bottom: 0; background: #c4e8f0;}
.ourjournyImgSlide::after{content: ''; position: absolute; right: 0; width:30%; height: 2px; bottom: 0; background: #c4e8f0;}
 
.aboutJourneyBottom {padding: 80px 0 180px; overflow: inherit;}

.aboutJourneyBottom .slick-slider {
    margin: -300px 0 0;
    
}

.aboutJourneyBottom .yearBox1{text-align: right;
    padding: 0 270px 0 0;}

    .aboutJourneyBottom .yearBox2{text-align: left;
        padding: 0 0 0 270px;}

        .aboutJourneyBottom .yearBox3{text-align: right;
            padding: 0 270px 0 0;}

            .aboutJourneyBottom .yearBox4{text-align: left;
                padding: 0 0 0  270px;}
.aboutJourneyBottom .slick-prev.slick-disabled:before, .aboutJourneyBottom .slick-next.slick-disabled:before {
    opacity: 1;
}

.aboutJourneyBottom .slick-prev.slick-disabled, .aboutJourneyBottom .slick-next.slick-disabled {
    opacity: 0.3;
}
 

.aboutJourneyBottom .ourjournyContSlide .slick-initialized .slick-slide.slick-current {
    opacity: 1 !important;
}

.aboutJourneyBottom .slick-track {
    height: auto !important;
}

  

.aboutJourneyBottom h4{font-size: 36px;  font-family: 'halyard_textblack';  font-weight: 800;     line-height: 35px;    margin: 0;   padding: 0; color: #818181;
}
.aboutJourneyBottom h3{font-size: 45px; font-family: 'halyard_textblack'; font-weight: 800; margin: 0 0 20px; padding: 0; line-height: 45px; color: #c5c2c2;}
.aboutJourneyBottom .ourjournyContSlide .slick-initialized .slick-slide.slick-current h4 {
    color: #000;
}

.aboutJourneyBottom .ourjournyContSlide .slick-initialized .slick-slide.slick-current h3 {
    color: #1f96b0;
}

.aboutJourneyBottom .ourjournyImgSlide h2 {
    color: #1f96b0;  font-size: 130px; font-family: 'halyard_textblack'; font-weight: 800; margin:-90px 0 0; letter-spacing: -6px; padding: 0; line-height: 135px; position: relative;
}
.aboutJourneyBottom .ourjournyImgSlide h2:before {   content: attr(title);   position: absolute;   -webkit-text-stroke:4px #fff;  top: 0;  z-index: 0;}

.aboutJourneyBottom .slick-arrow {
    bottom:-84px;
     height: 50px;
    opacity: 1;
    top: auto;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: all .5s ease;
    width: 70px;
    z-index: 99;
}
.aboutJourneyBottom .slick-arrow::before{display: none;}
.aboutJourneyBottom .slick-prev {
    background: url(../../asstes/images/journy-arrow-right.png) no-repeat 50%;
    background-size: 100%;
    left: auto;
    right: calc(30% + 70px);
}
.aboutJourneyBottom .slick-next {
    background: url(../../asstes/images/journy-arrow-left.png) no-repeat 50%;
    background-size: 100%;
    right: 30%;
}

.mdmessage {
    padding: 60px 0 0;
    background: #e0f5fa;
}
.mdmessage .tbTitle h2{margin: 0 0 20px; font-size: 60px;    text-transform: unset;
    line-height: 60px;}

.mdmessage .tbTitle p {
    margin: 0 0 10px;
    width: 75%;
}

.mdmessageRight{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}

@media screen and (max-width:1680px){
    .mdmessage .tbTitle p {    width: 90%;  }
  .ourjournyImgSlide {  width: 380px;}
   .aboutJourneyBottom .yearBox1 {  padding: 0 230px 0 0;   }
   .aboutJourneyBottom .yearBox2 {   padding: 0 0 0 230px;   }
   .aboutJourneyBottom .yearBox3 {  padding: 0 230px 0 0;   }
   .aboutJourneyBottom .yearBox4 {   padding: 0 0 0 230px;  }
   .aboutJourneyBottom h3 {  font-size: 35px;   margin: 0 0 15px;}
   .aboutJourneyBottom .slick-slider {    margin: -257px 0 0;}
   .aboutJourneyBottom .ourjournyImgSlide h2 {  font-size: 120px;}
    }


@media screen and (max-width:1600px){
    .mdmessage .tbTitle p {width: 100%;}
    .aboutJourneyBottom .ourjournyImgSlide h2 {font-size: 110px;}
    .mdmessage .tbTitle h2 {margin: 0 0 20px;font-size: 55px;line-height: 55px;}
    .aboutJourneyBottom h4 {font-size: 28px;line-height: 22px;}
    .aboutJourneyBottom h3 {font-size: 32px;}
}

@media screen and (max-width:1480px){
    .mdmessage .tbTitle h2 {margin: 0 0 20px;font-size: 50px;line-height: 50px;text-transform: unset;}
    .aboutJourneyBottom {padding: 80px 0 80px;}
}


@media screen and (max-width:1440px){
 .ourjournyImgSlide {  width: 320px;}
   .aboutJourneyBottom .yearBox1 {  padding: 0 190px 0 0;   }
   .aboutJourneyBottom .yearBox2 {   padding: 0 0 0 190px;   }
   .aboutJourneyBottom .yearBox3 {  padding: 0 190px 0 0;   }
   .aboutJourneyBottom .yearBox4 {   padding: 0 0 0 190px;  }
   .aboutJourneyBottom h3 {     font-size: 28px;   margin: 0 0 10px; }
   .aboutJourneyBottom .ourjournyImgSlide h2 {  font-size: 95px;}
   .aboutJourneyBottom .slick-slider {   margin: -228px 0 0;}
   .aboutJourneyBottom .textBlack.tbTitle h2 {  font-size: 100px;  line-height: 120px;}
}

@media screen and (max-width:1200px){
    .ourjournyImgSlide {width: 290px;top: 196px;}
    .aboutIntrosec h4 {  font-size: 40px; }
    .aboutIntrosec .tbTitle h2 {  font-size: 69px;}
    .aboutJourneyBottom .textBlack.tbTitle h2 {font-size: 75px;line-height: 81px;}
    .aboutJourneyBottom .yearBox1 {padding: 0 167px 0 0; }
    .aboutJourneyBottom .yearBox3 {padding: 0 167px 0 0; }
    .aboutJourneyBottom .yearBox2 {padding: 0 0 0 167px; }
    .aboutJourneyBottom .yearBox4 {padding: 0 0 0 167px; }
    .aboutJourneyBottom h3 {font-size: 30px;line-height: 30px;}
    .aboutJourneyBottom h4 {font-size: 30px;line-height: 30px;}
    .aboutJourneyBottom .slick-slider {margin: -229px 0 0;}
    .aboutJourneyBottom .ourjournyImgSlide h2 {font-size: 110px;  line-height: 110px;}
    .aboutJourneyBottom .slick-arrow {width: 50px;}
    .aboutJourneyBottom .ourjournyImgSlide h2 {font-size: 85px;  line-height: 106px;}
    .aboutJourneyBottom .textBlack.tbTitle {margin: -125px 0 130px;}
}

@media screen and (max-width: 800px){   
    .aboutJourneyBottom .slick-slider {margin: 0;}
    .aboutJourneyBottom .yearBox1{text-align: center;padding: 0;}
    .aboutJourneyBottom .yearBox2{text-align: center;padding: 0;}
    .aboutJourneyBottom .yearBox3{text-align: center;padding: 0;}
    .aboutJourneyBottom .yearBox4{text-align: center;padding: 0;}
    .aboutJourneyBottom .ourjournyContSlide .slick-initialized .slick-slide {width: 100% !important;     opacity: 1 !important;   margin: 0;}
    .ourjournyImgSlide {   top: 0;   }
    .aboutJourneyBottom .slick-prev {     right: calc(40% + 70px);    }
    .aboutJourneyBottom .slick-next {  right: 40%;    }
    .aboutJourneyBottom  .ourjournyImgSlide .slick-prev { display: none !important;}
    .aboutJourneyBottom  .ourjournyImgSlide .slick-next { display: none !important;}
    .ourjournyImgSlide::before{display: none;}
    .ourjournyImgSlide::after{display: none;}
    .ourjournyImgSlide {  padding: 0;}
    .ourjournyContSlide{position: relative; padding: 0 0 60px;}
    .ourjournyContSlide::before {content: '';position: absolute;left: 0;width: 38%;height: 2px;bottom: 0;background: #c4e8f0;}
    .ourjournyContSlide::after {content: '';position: absolute;right: 0;width: 30%;height: 2px;bottom: 0;background: #c4e8f0;}
    .aboutJourneyBottom h4 {display: none;}
    .aboutIntrosec p br{display: none;}
    .aboutJourneyBottom .ourjournyContSlide .slick-initialized .slick-slide {margin: 0 0 30px;}
    .aboutJourneyBottom .slick-track {transform: inherit !important;}  
    .aboutJourneyBottom .ourjournyContSlide .slick-initialized .slick-slide {opacity: 0 !important;}
    .aboutJourneyBottom .ourjournyContSlide .slick-initialized .slick-slide {position: absolute;}
    .aboutJourneyBottom .textBlack.tbTitle h2 {   -webkit-text-stroke: 4px #fff;}
    .aboutJourneyBottom .textBlack.tbTitle {   margin: -125px 0 56px;   }
    .aboutIntrosec h4 {    font-size: 35px;   }
    .aboutIntrosec {    padding: 73px 46px;}
    .aboutIntrosec .tbTitle h2 {   font-size: 50px;     }
    .aboutJourneyBottom .textBlack.tbTitle h2:before{display: none;}
}

@media screen and (max-width: 600px){
    .ourjournyImgSlide { width: 100%;  top: 0; }
    .aboutIntrosec .tbTitle h2 {   font-size: 50px;  }
    .aboutIntrosec h4 {    font-size: 28px;  }
    .aboutIntrosec {    padding: 60px 25px;}
    .aboutJourneyBottom .textBlack.tbTitle h2 {     font-size: 60px;   line-height: 77px;  -webkit-text-stroke: 4px #fff;}
    .mdmessageLeft{    order: 2;}
    .mdmessageRight { order: 1;  padding: 0 35px;  }
    .aboutJourneyTop  .IPBBgimg {padding-top: 33.3%;    }
}

@media screen and (max-width: 500px){
    .ourjournyImgSlide { width: 100%;  top: 0; }
    .aboutIntrosec .tbTitle h2 {font-size: 50px;  }
    .aboutIntrosec h4 {font-size: 28px;  }
    .aboutIntrosec {padding: 60px 25px;}
    .aboutJourneyBottom .textBlack.tbTitle h2 {font-size: 50px;line-height: 65px;-webkit-text-stroke: 3px #fff;}
    .aboutJourneyBottom {padding: 80px 0 80px;}
    .aboutJourneyBottom .slick-prev {right: calc(30% + 70px);}
    .aboutJourneyBottom .slick-next {right: 32%;}
}

@media screen and (max-width: 420px){
    .aboutIntrosec .tbTitle h2 {font-size: 40px;letter-spacing: -1px;}
    .aboutIntrosec h4 {font-size: 22px;margin-bottom: 15px;}
    .aboutIntrosec {padding: 30px 15px;}
    .aboutJourneyBottom .textBlack.tbTitle h2{-webkit-text-stroke: 1px #fff;}
    .ourjournyContSlide {padding: 0 0 20px;}
    .aboutJourneyBottom .slick-arrow {bottom: -44px;}
}


 


