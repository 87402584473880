.contactPage .BanContSec{background: #12adc5; color: #fff;  top: 30px;}
.contactPage .BanContSec .row{width:100%; position: relative;
    z-index: 100;} 
.contactPage .BanContSec  h1 { margin: 0 15px;  color: #ffffff;  font-family: 'halyard_textblack';  text-transform: uppercase; line-height: 0.8;  font-size: 120px;   letter-spacing: -2px;  padding-bottom: 13px;}
.contactPage .BanContSec .cBox{display: -webkit-box;  display: -ms-flexbox;  display: flex;   -webkit-box-align: center;  -ms-flex-align: center;    align-items: center; color:#fff;
font-size: 30px; padding:30px 30px; line-height: 35px; font-weight: 300;}
.contactPage .BanContSec .col-md-4 p{padding: 0 0 0 20px;}
.contactPage .BanContSec .cBox strong{font-weight: 800; display: block;}
.contactPage .BanContSec .cBox .smediaul li a {
    color: #12adc5;
     opacity: 1;
    background: #ffffff;
    font-size: 17px;
    transition: all 0.5s ease;
}
.contactPage .BanContSec .cBox .smediaul li a:hover {
    color: #fff;
     opacity: 1;
    background: #12adc5;
}
.contactPage .BanContSec .col-md-8 {
    border-right: solid 1px #41bdd1;
    padding: 0 0 0 40px;
    margin: 0;
     
}
.contactPage .BanContSec .col-md-6 {
    border-left: solid 1px #41bdd1;
    padding: 0;
    margin: 0;
}
.contactPage .BanContSec .cBox{
    border-bottom: solid 1px #41bdd1;
}
.contactPage .BanContSec .cBox .smediaul {
   margin:5px 0 0;
}
.contactPage .BanContSec .cBox .Icon {  width: 80px; }
.contactPage .BanContSec .cBox .Text{ padding: 0 0 0 18px;}
.contactPage .BanContSec .cBox .Icon img { 
    width: 100%;
}
.contactPage .BanContSec .cBox:last-child{
    border-bottom:none;
}
.contactPage .BanContSec .cBox a{color: #fff;}

.contactFormSec{padding: 0;}
.contactFormSec h2{    padding: 0; margin: 0;  font-size: 90px;  color: #12adc5;  line-height: 90px;  letter-spacing: -5px;   font-weight: 800;}

.contactFormSec .ProContSec{top: 300px;}
.contactFormSec .RightImg {position: absolute;  width: 100%;   text-align: right; margin-top: -205px;}
.contactFormSec .RightImg img{width: auto;}
.contactFormTop{margin: 0 0 60px;}
.contactFormTop p{font-size: 18px;}
.contactFormSec input{
border: none;
border-bottom: solid 1px #ccc;
border-radius: 0;
padding: 7px 0;
font-size: 20px;
background: transparent
}

.contactFormSec select{
    border: none;
    border-bottom: solid 1px #ccc;
    border-radius: 0;
    padding: 7px 0;
    font-size: 20px;
    width: 100%;
    height: 49px;
    background: transparent
    }

    .contactFormSec select:focus-visible {
        outline: none;
    }

    .contactFormSec textarea{
        border: none;
    border-bottom: solid 1px #ccc;
    border-radius: 0;
    padding: 7px  0;
    font-size: 20px;
    height: 54px;
    margin: 0 0 50px;
    background: transparent
        }

        .contactFormSec .mb-3 {
            margin-bottom: 3rem!important;
            padding: 0 15px 0 0;
            position: relative;
        }

        .contactFormSec .submitBtn {
            position: relative;
            display: block;
            border: 1px solid transparent;
            background: #3ab2cc;
            color: #fff;
            display: block;
            line-height: 45px;
            border-radius: 30px;
            padding: 0 40px;
            font-size: 20px;
            font-weight: 600;
            box-shadow: 0 0 0 7px rgb(255 255 255 / 7%);
            display: block;
        }

        .contactFormSec .submitBtn:hover {
            background: #fff;
            color: #3ab2cc;
            border-color: #3ab2cc;
        }

        .contactFormSec ::-webkit-input-placeholder { /* Edge */
            color: #000;
          }
          
          .contactFormSec  :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color:#000;
          }
          
          .contactFormSec ::placeholder {
            color: #000;
          }

          .contactFormSec .textfield__label::after {
            position: relative;
            top: -3px;
            left: 1px;
            display: inline-block;
            content: "*";
            color: #12adc5;
          }

          .textfield__label {
            position: absolute;
            top: 12px;
            left: 0;
            display: block;
            font-size:20px;
            line-height: 24px;
            color:#000;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            cursor: text;
          }
          .textfield__label::after {
            position: relative;
            top: -3px;
            left: 1px;
            display: inline-block;
            content: "*";
            color: #d0011b;
          }
          .textfield__input {
            display: block;
            box-sizing: border-box;
            height: 48px;
            width: 100%;
            padding: 11px 14px;
            border: 1px solid #cccccc;
            border-radius: 5px;
            background: #ffffff;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            outline: none;
            transition: all 0.25s linear;
          }
          .textfield__input:focus {
            border-color: #3a82c6;
          }
          .textfield__input:disabled {
            border-color: #e6e6e6;
            background: #f9fafb;
          }
          .textfield__input::-moz-placeholder {
            color: transparent;
          }
          .textfield__input:-ms-input-placeholder {
            color: transparent;
          }
          .textfield__input::placeholder {
            color: transparent;
          }
          .textfield__input:focus + .textfield__label, .textfield__input:valid + .textfield__label, .textfield__input:invalid + .textfield__label, .textfield__input:disabled + .textfield__label {display: none;}
          .textfield__input[value=''] + .textfield__label{display: block;}
       body .form-control:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: #3ab2cc;
            outline: 0;
            box-shadow: none;
        }

.ExpertForm .field-error{background: red;font-size: 13px;color: #fff;padding: 0 10px;}
@media screen and (max-width: 1720px) {
    .contactPage .BanContSec h1{font-size: 100px;}
    .contactPage .BanContSec .cBox .Icon {width: 50px;}
    .contactPage .BanContSec .cBox{padding: 20px 20px;font-size: 26px;}
    
}
    @media screen and (max-width: 1580px) {
  .contactPage .BanContSec h1 {    font-size: 80px; }
          .contactPage .BanContSec .cBox .Icon {  width: 50px; }
          .contactPage .BanContSec .cBox .Text{ padding: 0 0 0 12px;}
          .contactPage .BanContSec .cBox {     font-size: 22px;   padding: 20px;  line-height: 30px;}
          .contactFormSec .RightImg {   margin-top: -185px; }
          .contactFormSec .RightImg img {   width: 44%;   }
          .contactFormSec h2 {   font-size: 70px;  line-height: 70px;  letter-spacing: -4px;  }
          .contactFormTop p {    font-size: 16px;  }
          .textfield__label {   font-size: 17px;}
          .contactFormSec select {  font-size: 17px;}
          .contactFormSec textarea {  font-size: 17px;}
        }


   @media screen and (max-width: 1480px) {
      .contactFormSec h2 {   font-size: 65px;  line-height: 65px;  letter-spacing: -4px;  }
      .contactFormSec .RightImg {
        margin-top: -152px;
    
    }
    .contactFormSec .ProContSec {
        top: 252px;
    }
    .contactFormSec {
        padding: 0 0 100px;
    }
    
    .textfield__label, .contactFormSec select, .contactFormSec textarea {font-size: 15px;padding: 0;}
    .textfield__input, .contactFormSec select{height: 40px;}
    .contactFormSec .mb-3 {margin-bottom: 2.5rem!important;}
    .contactFormSec .submitBtn{font-size: 17px;}


  }


  @media screen and (max-width: 1280px){
 
.contactFormSec .ProContSec {
    top: 223px;
}
.contactFormSec .RightImg img {
    width: 39%;
}
.contactFormSec .RightImg {
    margin-top: -116px;
}
.contactFormSec .mb-3 {
    margin-bottom: 2rem !important;}
    .contactFormSec {
        padding: 0 0 150px;
    }

}



@media screen and (max-width: 1200px){
 
    .contactFormSec .ProContSec {
        top: 223px;
    }
    .contactFormSec .RightImg img {
        width: 39%;
    }
    .contactFormSec .RightImg {
        margin-top: -116px;
    }
    .contactFormSec .mb-3 {
        margin-bottom: 2rem !important;}
        .contactPage .BanContSec h1 {
            font-size: 70px;
        }
        .contactPage .BanContSec .cBox {
            font-size: 18px;  line-height: 26px;
        }
        .contactFormSec h2 {  font-size: 60px;   }
        .contactFormTop {   margin: 0 0 40px; }
        .contactFormSec .RightImg {
            margin-top: -102px;
        }
        .contactFormSec .ProContSec {
            top: 190px;
        }
    }

    @media screen and (max-width: 1024px){
        .contactFormSec {
            padding: 0 0 0px;
        }
        section.contactFormSec{overflow: visible;}
    }

    @media screen and (max-width: 800px){
 .contactFormSec .RightImg {
            margin-top: 130px;
        }
    .contactPage .BanContSec .col-md-4{width: 100%;}
    .contactPage .BanContSec .col-md-8{width: 100%;}
    .contactPage .BanContSec {  height: auto;  }
    .contactPage .BanContSec {
        top: 97px;
    }
    .contactFormSec .ProContSec {
        top: 120px;
    }
    .contactFormSec h2 {
        font-size: 47px;
        letter-spacing: -3px;
    }
    .contactFormTop p {
        font-size: 14px;
    }
    .contactFormSec .mb-3 {
        margin-bottom: 1rem !important;
    }
    .textfield__label {
        font-size: 15px;
    }
    .contactFormSec select {
        font-size: 15px;
    }
    .contactFormSec textarea {
        font-size: 15px;
    }
    .contactFormSec .submitBtn { font-size: 18px;}
}


@media screen and (max-width: 767px){


.contactFormSec .ProContSec {
    top: 0;
    position: relative;
}
.contactFormSec {
    padding: 80px 20px;
}
.contactFormSec .RightImg {
    margin-top: 0;
    display: none;
}
.contactPage .InnerPBanSec .IPBBgimg{display: none;}
.contactPage .BanContSec {
    top: 0;
    position: relative;
    padding: 80px 0 20px;
}
.contactPage .BanContSec .container{padding-bottom: 0;}
.contactFormSec h2 {
    font-size: 45px;
    letter-spacing: -3px;
    line-height: 45px;
}
.contactPage .BanContSec .col-md-6 {
    border-left: none;}
    .contactPage .BanContSec .cBox {  padding: 20px 0;}
    .contactPage .BanContSec .cBox:last-child {
        border-bottom: solid 1px #41bdd1;
    }
    .contactPage .BanContSec .col-md-8 {
        border-right: none;
    }

    .contactPage .BanContSec .col-md-6:last-child .cBox:last-child {
        border-bottom:none;
    }
    .contactFormSec input{font-size: 14px;}
}