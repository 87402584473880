.ustudyPage .ProContSec {background: url(../../../asstes/images/ustudyintroBg.jpg) no-repeat bottom right;background-size: cover;}
.ustudyPage .iconBox img {width: 85px;}
.ustudyPage .iconBox {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin:0 auto 10px;background: #fff;border-radius: 6px;width: 95%;}
.ustudyPage .iconBox strong {padding-left: 20px;font-size: 18px;line-height: 1.2;letter-spacing: -0.5px;color: #000000;font-weight: 600;}
.ustudyPage .ustudyHigl .slick-arrow {bottom:-77px;height: 50px;opacity: 1;top: auto;-webkit-transform: scale(1);transform: scale(1);transition: all .5s ease;width: 70px;z-index: 99;}
.ustudyPage .ustudyHigl.ProAIPowSec {background: #2b9fb7;padding: 60px 0;overflow: hidden;}
.ustudyPage .ustudyHigl .slick-arrow::before{display: none;}
.ustudyPage .ustudyHigl .slick-prev {background: url(../../../asstes/images/us-arrow-left.png) no-repeat 50%;background-size: 100%;left: 38%;}
.ustudyPage .ustudyHiglRight{padding: 0 0 60px;}
.ustudyPage .ustudyHigl .slick-next {background: url(../../../asstes/images/us-arrow-right.png) no-repeat 50%;background-size: 100%;right: 38%;}
.ustudyPage .ustudyHigl .slick-disabled{opacity: 0.5;}
.ustudyPage .ustudyHiglRight:after, .ustudyPage .ustudyHiglRight:before {background: #ffffff;bottom: 6px;content: "";height: 2px;position: absolute;width: 32%;opacity: 0.2;}
.ustudyPage .ustudyHiglRight:after {right:0;}
.KampusRobSolSec.ustudyRed .ProRobBtmcol{width: 80%;}
.KampusRobSolSec.ustudyRed .RobSolItems { padding: 20px  0 0;}
.KampusRobSolSec.ustudyRed .ProRobBtmcol .RobSolItem {flex: 1 0 20.3333%; margin-bottom: 0px;}
.ProAIPowSec.ustudyHigl .ProAIPowSCol .ProAIPowIMg{left: 0;bottom: 0;-webkit-transform: translateY(10%);-ms-transform: translateY(10%);transform: translateY(10%);}


.ustudyPage .ustudyHigl .iconBox img {padding: 15px;}

@media screen and (max-width:1620px) {
    .ustudyPage .iconBox img {width: 68px;}
    .ustudyPage .iconBox strong{font-size: 16px;}
}

@media screen and (max-width:1520px) {
    .ustudyPage .iconBox strong {font-size: 15px;}
    .ustudyPage .ustudyHigl .slick-arrow{width: 55px;height: 30px;bottom: -69px;}
}

@media screen and (max-width:1420px) {
    .ustudyPage .iconBox strong {font-size: 14px;}
    .ustudyPage .iconBox img {width: 62px;}
}
@media screen and (max-width:800px) {
    .ProContSec.UstdyIntroC{padding-bottom: 40px;background-color: #f0f8fb;}
    .KampusRobSolSec.ustudyRed .ProRobBtmcol {width: 100%;}
    .KampusRobSolSec.ustudyRed .ProRobBtmcol .RobSolItem {-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;margin-bottom: 20px;}
}
@media screen and (max-width:550px) {
    .ustudyPage .ustudyHigl.ProAIPowSec {padding: 40px 20px;}
    .UstdyIntroC .ktbkList{padding-top: 20px;}
    .ustudyPage .ustudyHigl .slick-arrow {width: 38px;}
    .ProRobSolSec.ustudyRed .tbTitle h2 br{display: none;}
    .KampusRobSolSec.ustudyRed .RobSolItems {padding: 0px 0 0;}
    .ProContSec.UstdyIntroC {padding-bottom: 86%;background-size: 200%;}
}