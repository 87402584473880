footer{background: #040d2d;padding: 80px 0;position: relative;z-index: 999; }
footer h4{color: #fff;font-size: 15px;text-transform: uppercase;font-weight: 500;letter-spacing: 2px;}
footer ul.footul{position: relative;}
footer ul.spcbtmul{margin-bottom: 20px;}
footer ul.footul li{padding: 5px 0;}
footer ul.footul li a{color: #fff;text-transform: uppercase;display: block;opacity: 0.7;font-size: 14px;}
footer ul.footul li a:hover{opacity:1;text-decoration: underline;}
footer ul.footMmenu li a{opacity: 1;font-size: 15px;letter-spacing: 1px;}
footer ul.footul li.nolink a{cursor: default;pointer-events: none;}
.copyrightcol{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: end;-ms-flex-align: end;align-items: flex-end;text-align: right;}
.copyrightcol p{margin: 0;}
.copyrightcol p.coprpara{color: rgb(255 255 255 / 70%);margin: 15px 0;text-transform: uppercase;}
.smediaul{display: -webkit-box;display: -ms-flexbox;display: flex;}
.smediaul li{margin-right: 6px;}
.smediaul li a{color: #fff;width: 35px;height: 35px;display: block;line-height: 35px;text-align: center;border: 1px solid #fff;border-radius: 50%;font-size: 14px;opacity: 0.7;cursor: default;pointer-events: none;}
.smediaul a li{color: #fff;width: 35px;height: 35px;display: block;line-height: 35px;text-align: center;border: 1px solid #fff;border-radius: 50%;font-size: 14px;opacity: 0.7;cursor: default;pointer-events: none;}
.smediaul li a:hover{opacity: 1;}
p.openlink{position: relative;}
p.openlink a{display: inline-block;margin: 0;padding: 3px 30px 3px 0;position: relative;color: rgb(255 255 255 / 70%);}
p.openlink a:hover{color: rgb(255 255 255 / 100%);}
p.openlink a::after{background: url(../../asstes/images/open-dd-logo.webp) no-repeat 0;background-size: 100%;border: 1px solid hsla(0,0%,100%,.36);border-radius: 50%;content: "";    height: 22px;    position: absolute;    right: 0;    top: 50%;    -webkit-transform: translateY(-50%);    transform: translateY(-50%);    width: 22px;}

.EnowSec{position: relative;z-index: 999;}
.EnowSecCont{position: absolute;width: 100%;height: 100%;top: 0;left: 0;z-index: 9;}
.EnowSecCont .container{position: relative;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.EnowInCont{margin-top: -12%;margin-right: 6%;}
.EnowInCont h6{font-size: 22px;color: #000;text-transform: uppercase;margin: 0;}
.EnowInCont h2{font-family: 'halyard_textblack';color: #1f96b0;font-size: 72px;line-height: 0.9;margin-bottom: 25px;}
.EnowInCont p{margin: 0;}
.EnowInCont p.btnpara{position: relative;margin-top: 20px;}
.EnowInCont p.btnpara a{display: inline-block;position: relative;background: #3ab2cc;color: #fff;line-height: 45px;border-radius: 30px;padding: 0 30px;font-size: 20px;font-weight: 600;box-shadow: 0 0 0 7px rgb(255 255 255 / 7%);}
.EnowInCont p.btnpara a:hover{background: #fff;color: #3ab2cc;}
.EnowInLink{position: absolute;bottom: 20px;right: 20px;text-align: center;}
.EnowInLink h6{font-size: 16px;color: #000;text-transform: uppercase;}
.EnowInLink a{display: block;}
.EnowInLink a img{height: 100%;width: 50%;display: block;    margin: 0 auto;}
.EnowInLink ul{display: -webkit-box;display: -ms-flexbox;display: flex;display: none; -webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.EnowInLink ul li{margin-right: 10px;}
.EnowInLink ul li:last-child{margin-right: 0px;}
.EnowInLink ul li a{display: block;}
.EnowInLink ul li a img{width: auto;height: 55px;}
.mobile-nav-sticky{display: none;}


@media screen and (max-width:1720px) {
    .EnowInCont{margin-right: 5%;}
    .EnowInCont h6 {font-size: 20px;}
    .EnowInCont h2{font-size: 66px;}
    .EnowInCont p.btnpara a{font-size: 17px;}
}

@media screen and (max-width:1620px) {
    footer {padding: 70px 0;}
    footer ul.footMmenu li a{font-size: 14px;}
    footer h4{font-size: 14px;}
    footer ul.footul li a{font-size: 13px;}
    footer ul.footul li {padding: 3px 0;}
    .smediaul li a{width: 32px;height: 32px;line-height: 32px;}
    .smediaul a li{width: 32px;height: 32px;line-height: 32px;}
    .EnowInCont h6 {font-size: 18px;}
    .EnowInCont h2 {font-size: 60px;}
    .EnowInCont p.btnpara a {font-size: 16px;}
    .EnowInLink h6 {font-size: 15px;}
    .EnowInLink ul li a img {height: 52px;}

}
@media screen and (max-width:1500px){
    footer {padding: 55px 0;}
    .EnowInLink h6 {font-size: 14px;}
    .EnowInLink ul li a img {height: 45px;}
    .EnowInCont h2 {font-size: 56px;margin-bottom: 18px;}
    .EnowInCont p.btnpara a {font-size: 15px;line-height: 38px;padding: 0 25px;}
}
@media screen and (max-width:1400px){
    .EnowInCont h6 {font-size: 16px;}
    .EnowInCont h2 {font-size: 52px;margin-bottom: 15px;}
    .EnowInLink h6 {font-size: 13px;letter-spacing: 0.5px;}
    .EnowInCont p.btnpara a {font-size: 14px;line-height: 34px;padding: 0 22px;}
    footer ul.footul li {padding: 2px 0;}
    .copyrightcol p.coprpara {margin: 12px 0;}
}
@media screen and (max-width:1300px){
    footer ul.footul li a {font-size: 12px;}
}

@media screen and (max-width:1100px){
    .EnowInCont h6 {font-size: 15px;}
    .EnowInCont h2 {font-size: 46px;margin-bottom: 15px;}
}
@media screen and (max-width:800px){
    .EnowSecBg{display: none;}
    .EnowSecCont {position: relative;}
    .EnowSecCont .container{padding-top: 13%;padding-bottom: 95%;background: url(../../asstes/images/FootMobEnqNowBg.jpg) no-repeat center bottom;background-size: 100%;}
    .EnowInCont {margin-top: 0;width: 100%;}
    .EnowInLink{position: relative;bottom: 0;right: 0;padding: 30px 0;}
    footer {padding:40px 10px 90px;}
    footer .row .col{-webkit-box-flex: 1;-ms-flex: auto;flex: auto;}
    footer .row .col:nth-child(2){display: none;}
    footer .row .col:nth-child(3){display: none;}
    footer .row .col:nth-child(4){display: none;}
    .smediaul li:last-child{margin-right: 0;}
    footer ul.footul {margin-bottom: 20px;padding:30px 10px 20px;border-bottom: 1px solid #363d57;}
    footer ul.footul::before{content: 'MENU';position: absolute;top: 0;color: #fff;font-size: 20px;font-weight: 500;}
    .copyrightcol{-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;text-align: center;}
    .copyrightcol p.coprpara{width: 100%;margin: 20px 0 5px;padding-top: 15px;border-top: 1px solid #363d57;}
    .copyrightcol p.coprpara br:nth-child(1){display: none;}
    .copyrightcol h4{margin-left: 12px;}
    .smediaul{margin-left: 12px;}
    p.openlink{width: 100%;}
    footer ul.footul li a {font-size: 16px;opacity: 0.7;}
    footer ul.footul li {padding: 8px 0;border-bottom: 1px solid #353d56;}
    footer ul.footul li:last-child {border-bottom: 0px solid #353d56;}   
    .mobile-nav-sticky {display: block;position: fixed;bottom:10px;left: 0;background: #1f96b0;width: 100%;z-index: 999;padding:6px 15px 5px;border-radius: 0px;box-shadow: 0 0 0px 5px rgb(31 150 176 / 44%);pointer-events:all;}
	.mobile-nav-sticky ul.mns-list {padding: 0;margin: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
	.mobile-nav-sticky ul.mns-list li {list-style: none;-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;text-align: center;width: 60px;margin: 0 auto;}
	.mobile-nav-sticky ul.mns-list li.mnv-logo{width: 75px;position: relative;}
	.mobile-nav-sticky ul.mns-list li a {display: block;color: #fff;text-transform: uppercase;font-weight: 300;font-size: 12px;}
	.mobile-nav-sticky ul.mns-list li a:hover{color:#222222;}
	.mobile-nav-sticky ul.mns-list li a strong{font-weight: 500;letter-spacing: 0.5px;font-family: 'Poppins', sans-serif;}
	.mobile-nav-sticky ul.mns-list li.mnv-logo .mnv-logo-img {display: block;width: 100%;padding-top: 100%;height: auto;position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);    box-shadow: 0 0 0 4px rgb(196 196 196 / 11%);border-radius: 50%;}
	.mobile-nav-sticky ul.mns-list li.mnv-logo .mnv-logo-img:before{content:"";display: block;width: 100%;padding-top: 100%;height: auto;background: #fff ;border-radius: 50%;margin: 0px 0;position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);}
	.mobile-nav-sticky ul.mns-list li.mnv-logo .mnv-logo-img:after{content:"";display: block;width:80%;padding-top: 80%;height: auto;background:  url(../../asstes/images/msticky-logocircle.webp) no-repeat center center;background-size: 100%;position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);}
	.mobile-nav-sticky ul.mns-list li a .mnv-icon-view {display: block;width: 25px;height: 25px;margin: 0 auto;}
	.mobile-nav-sticky ul.mns-list li a .mnv-icon-view {}
	.mobile-nav-sticky ul.mns-list li a .mnv-icon-view.mnv-icon-search {background: url(../../asstes/images/msticky-search-icon.webp) no-repeat center center;background-size: 100%;}
	.mobile-nav-sticky ul.mns-list li a .mnv-icon-view.mnv-icon-call {background: url(../../asstes/images/msticky-call-icon.webp) no-repeat center center;background-size: 100%;}
	.mobile-nav-sticky ul.mns-list li a .mnv-icon-view.mnv-icon-enquire {background: url(../../asstes/images/msticky-enquire-icon.webp) no-repeat center center;background-size: 100%;}
	
	.mobile-nav-sticky ul.mns-list li a.hamburger-icon b {display: -webkit-box;display: -ms-flexbox;display: flex;position: relative;width:18px;height: 25px;padding: 0;box-sizing: border-box;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;-webkit-box-align: end;-ms-flex-align: end;align-items: flex-end;background: transparent;border-radius: 0%;margin: 0 auto;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;padding: 5px 0 0;}
	.mobile-nav-sticky ul.mns-list li a.hamburger-icon b .line {display: block;width: 2px;height: 100%;background: #fff;position: relative;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
	.mobile-nav-sticky ul.mns-list li a.hamburger-icon b .line-one {height:50%;}
	.mobile-nav-sticky ul.mns-list li a.hamburger-icon b .line-two {height: 100%;}
	.mobile-nav-sticky ul.mns-list li a.hamburger-icon b .line-three {height: 90%;}
    .EnowInCont h2 {font-size: 38px;margin-bottom: 10px;}

    
}

@media screen and (max-width:420px){
    .EnowSecCont .container{padding-bottom: 104%;}
    .EnowInCont h2 {font-size: 35px;}

}
