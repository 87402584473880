.ueHeader{padding: 10px 40px 10px;position: fixed;box-shadow: 0 0 0px 0px rgb(51 51 51 / 0%);background: transparent;border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;top: 0;transform: translateY(0%);left: 40px;right: 40px;z-index: 9999;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
.ueHeader.isSticky{transform: translateY(-100%);}
.ueHeader *{-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
.ueHeader .row{-webkit-box-align: center;-ms-flex-align: center;align-items: center;position: relative;}
.ueHeader .row::before{content: "";position: absolute;width:85%;height: 1.5px;bottom: 10px;right: 20px;background: #3da7c9;pointer-events: none;}
.HLogoD{width: 14%;position: relative;}
.HLogoD a{display: block;}
.HLogoD img{width: 90%;height: 100%;}
.HMenuD{width:auto;margin:5px auto -40px;position: relative;}
nav.nav{padding: 0 30px;background: #fff;border-radius: 30px;box-shadow: 0 0 0 7px rgb(58 178 204 / 7%);}
ul.uemainMenu{margin: 0;padding: 0;list-style: none;display: -webkit-box;display: -ms-flexbox;display: flex;height: 50px;}
ul.uemainMenu li{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 0 20px;position: relative;}
ul.uemainMenu li:before{content:'';position: absolute;width:1px;height:40%;right:0;bottom: 30%;background-color: #dddddd;pointer-events: none;opacity: 0.8;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
ul.uemainMenu li:last-child:before{opacity: 0;visibility: hidden;}
ul.uemainMenu li a{color: #000000;display: block;text-decoration: none;text-transform: uppercase;font-weight: 500;letter-spacing: 0.5px;font-size: 15px;}
ul.uemainMenu li a:hover{color: #3da7c9;}
ul.uemainMenu li a:focus-visible{outline: none;}
.HHumMD{width: 14%;margin-top: 10px;margin-bottom:-34px;position: relative;}
.HumMenu{width:64px;height:48px;margin-left: auto;background: #fff;border-radius: 30px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.linelist{width: 22px;height: 16px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: end;-ms-flex-align: end;align-items: flex-end;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.HumMenu .line{width: 100%;height: 2px;background: #3da7c9;}
.HumMenu .line.line-three{width: 70%;}
.HumMenu:hover{background: #3da7c9;}
.HumMenu:hover .line{background: #fff;}
.HumMenu:hover .line.line-three{width: 100%;}

.scrolled  ul.uemainMenu li:before{bottom: -9px;height: 50%;}


.ueHeader.scrolled {padding:7px 10px 9px;background: #fff;border-bottom-left-radius: 0px;border-bottom-right-radius: 30px;box-shadow: 0 0 2px 1px rgb(51 51 51 / 20%);}
.ueHeader.scrolled .HMenuD{margin: 0px auto 0px;}
.ueHeader.scrolled .HHumMD{margin-top: 0;margin-bottom: 0;}
.ueHeader.scrolled .row::before{opacity: 0;visibility: hidden;}
.ueHeader.scrolled .HLogoD img {height: 100%;width: 65%; display: block;}
.ueHeader.scrolled nav.nav{box-shadow: 0 0 0 0px rgb(58 178 204 / 7%);}


#HamburgerMegamenu{position: fixed;width: 100%;height: 100%;top:0%;opacity: 0;-webkit-transform: translateY(-100%);-ms-transform: translateY(-100%);transform: translateY(-100%);z-index:0;pointer-events: none; -webkit-transition: all 0.5s ease;-o-transition: all 0.5s ease;transition: all 1s ease;padding:0; background: url(../../asstes/images/HumMegaMenuBgImg.jpg) no-repeat center center;background-size: 100%;overflow: hidden; display:block;}
#HamburgerMegamenu.mmmm-opened{opacity: 1;-webkit-transform: translateY(0%);-ms-transform: translateY(0%);transform: translateY(0%);z-index: 999999;pointer-events: all;}
#HamburgerMegamenu.mmmm-opened:after{content:'';background-color:white;}
#HamburgerMegamenu .mm-main-container{max-width:100%;margin:0px auto 0;padding:30px;position: relative;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
#HamburgerMegamenu .megamenu-header{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;/* border-bottom:1px solid rgb(255 255 255 / 11%); */padding:0px 20px 60px;position:relative;width: 100%;}
#HamburgerMegamenu .megamenu-header a{width: 14vw;height: auto;}
#HamburgerMegamenu .megamenu-header a img{display:block;margin: 0;width:100%;height:auto;max-width: inherit;-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
#HamburgerMegamenu .Hamburgermm-close{color:#ffffff;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;font-weight: 400;z-index: 99;background: transparent;border: none;padding: 0;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;cursor: pointer;-webkit-transition: all 1s ease;-o-transition: all 1s ease;transition: all 1s ease;position: absolute;top: 0%;right: 20px;-webkit-transform: translate(0px,0%);-ms-transform: translate(0px,0%);transform: translate(0px,0%);}
#HamburgerMegamenu .Hamburgermm-close img {width: 45px;padding:0 0 5px 0;border:0px solid black;border-radius: 0%;/* -webkit-filter: brightness(1) invert(1);filter: brightness(1) invert(1); */}
#HamburgerMegamenu .Hamburgermm-close span {font-size: 16px;line-height: 20px;padding: 0px 0 0 0px;margin: 0px 0 0;display: block;font-family: 'Roboto', sans-serif;font-weight: bold;text-transform: uppercase;letter-spacing:0px;height:auto;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;overflow: hidden;}
#HamburgerMegamenu .Hamburgermm-close:hover span {height:auto;}
#HamburgerMegamenu .MobMegaMenuSec{display:none;}
#HamburgerMegamenu .megamenu-container{display: -webkit-box;display: -ms-flexbox;display: flex;margin: 0px auto 0;position:relative;padding:20px 20px 0;width:80%;height:calc(100% - 91px);-webkit-box-flex: 1;-ms-flex: 1;flex: 1;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
#HamburgerMegamenu .megamenu-container:before {content: "";position: absolute;width: 1px;height: 100%;top:-5px;left:50%;background:#fff;z-index: 9;opacity: 0;pointer-events: none;}
#HamburgerMegamenu .megamenu-container>div{position:relative;-webkit-box-flex: 0;-ms-flex:0 0 auto;flex:0 0 auto;}
#HamburgerMegamenu .megamenu-container>div:before{content:"";position:absolute;}
#HamburgerMegamenu .mmMenuCon{}
#HamburgerMegamenu .mmMenuCon ul.myclass {margin: 0;padding: 0;list-style: none;}
#HamburgerMegamenu .mmMenuCon ul.myclass>li{position:relative;}
#HamburgerMegamenu .mmMenuCon ul.myclass>li>a{display: block;line-height: 2;color: #fff;text-transform: uppercase;font-size: 17px;border-bottom: 1px solid rgb(255 255 255 / 7%);}
#HamburgerMegamenu .mmMenuCon ul.myclass>li:last-child>a{border-bottom: 0px solid rgb(255 255 255 / 7%);}
#HamburgerMegamenu .mmMenuCon ul.myclass li a:hover{text-decoration: underline;}
#HamburgerMegamenu .stcol {padding-top:20px;}
#HamburgerMegamenu .stcol h6{margin: 0 0px 15px;font-size: 20px;line-height: 1;color: #fff;text-transform: uppercase;}
#HamburgerMegamenu .stcol a.contlink {display: block;color: #fff;font-size: 15px;line-height: 2;padding-left: 40px;margin-bottom: 4px;position: relative;}
#HamburgerMegamenu .stcol a.contlink:before{content:"";position: absolute;width:27px;height:100%;top:0;left:0;-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);}
#HamburgerMegamenu .stcol a.contlink.phoneno:before{background:url(../../asstes/images/mob-call-icon.svg) no-repeat center center;background-size:25px;}
#HamburgerMegamenu .stcol a.contlink.emailid:before{background:url(../../asstes/images/mob-mail-icon.svg) no-repeat center center;background-size:25px;}

.megamenu-header{}
.MainMenuList{border-top: 1px solid rgb(255 255 255 / 11%);border-bottom: 1px solid rgb(255 255 255 / 11%);padding:30px 4%;display: -webkit-box;display: -ms-flexbox;display: flex;}
.mMenuListUl{-webkit-box-flex: 0;-ms-flex: 0 0 33.3333%;flex: 0 0 33.3333%;padding-left:30px;border-left: 1px solid rgb(255 255 255 / 11%);}
.mMenuListUl li{display: -webkit-box;display: -ms-flexbox;display: flex;}
.mMenuListUl li a{display: block;font-size: 32px;color: #fff;font-weight: 200;text-transform: uppercase;}
.mMenuListUl li a:hover{text-decoration: underline;}
.OurProductsList{border-bottom: 1px solid rgb(255 255 255 / 11%);padding:40px 4%;}
ul.OPLul{display: -webkit-box;display: -ms-flexbox;display: flex;}
ul.OPLul li{-webkit-box-flex: 0;-ms-flex:0 0 auto;flex:0 0 auto;margin-right: auto;position: relative;padding-top: 20px;}
ul.OPLul li::before{content: 'Our Products';position: absolute;color: #fff;top: 0;left: 0;font-size: 14px;text-transform: uppercase;opacity: 0.8;}
ul.OPLul li::after{content: '';position: absolute;width:1px;height:100%;top:0%;right:-80px;background: rgb(255 255 255 / 11%); opacity: 0.8;pointer-events: none;}
ul.OPLul li:last-child::after{opacity: 0;visibility: hidden;}
ul.OPLul li:last-child{margin-right: 0;}
ul.OPLul li a{font-family: 'halyard_textblack';color: #ffffff;font-size: 50px;line-height: 0.8;display: block;text-transform: uppercase;}
ul.OPLul li a:hover{text-decoration: underline;}

.ENEnowInLink h6 {font-size: 16px;color: #fff;text-transform: uppercase;text-align: center;}
.ENEnowInLink a {font-size: 200%;color: #fff;text-transform: uppercase;text-align: center;font-family: 'halyard_textblack';display: block;}
.ENEnowInLink a img{width:auto;height:70px;}
.ENEnowInLink ul {display: -webkit-box;display: -ms-flexbox;display: flex;display: none; -webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.ENEnowInLink ul li {margin-right: 10px;}
.ENEnowInLink ul li:last-child {margin-right: 0px;}
.ENEnowInLink ul li a {display: block;}
.ENEnowInLink ul li a img {width: auto;height: 55px;border-radius: 15px;display: block;}

.mmENContin{display: -webkit-box;display: -ms-flexbox;display: flex;padding: 80px 4% 0;}
.mmENCLeft{margin-right: auto;}
.mmENCLeft h6{font-size: 16px;color: #fff;text-transform: uppercase;}
.mmENCLeft h2{font-family: 'halyard_textblack';font-size: 48px;opacity: 0.8;color: #ffffff;line-height:1;}
.mmENCLeft a{font-size: 26px;opacity: 0.8;color: #ffffff;font-weight: 600;display: inline-block;position: relative;}
.mmENCLeft a:before{content:'';position: absolute;width: 50px;height:15px;top:50%;right:0;background: url(../../asstes/images/Verti-ArrowRightIcon.png) no-repeat center center;background-size:auto 100%;-webkit-transform: translate(100%, -50%);-ms-transform: translate(100%, -50%);transform: translate(100%, -50%);}
.mmENCLeft a:hover{opacity: 1;text-decoration: underline;}
.mmENCRight{margin-left: auto;}

/* mega menu css */
ul.uemainMenu li.dropdown a{position: relative;}
.dropdown {   padding: 0;  }
   .dropdown .dropbtn {  font-size: 16px;    border: none;  outline: none;
      color: #000;  padding: 14px 16px; background-color: inherit;  font: inherit;  margin: 0;  font-weight: 500;  }
  .navbar a:hover, .dropdown:hover .dropbtn {   color: #238aa0;   }
   .dropdown-content {  display: none;  position: absolute;  width: 800px;  /*left: -277px;*/  left: 50%;  top: 50px;   z-index: 1; transform: translateX(calc(-50% + 28px));}

    .dropdown-content .row {   background-color: #ffffff;    z-index: 1;   padding:0 30px;   border-radius: 10px;   box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      border-top: solid 5px #040d2d; }
  .dropdown-content .row:after {
      content: ''; width: 0; height: 0; border-left: 8px solid transparent; border-right: 8px solid transparent; border-bottom: 8px solid #040d2d;
position: absolute; left: 46%; width: 0; height: 0; top: -13px;   }
  .dropdown-content .header {   background: #fff; padding: 16px; color: #238aa0; }
   .dropdown:hover .dropdown-content {  display: block; }
   .dropdown .dropbtn:after {   content: '';  position: absolute;  right:0;
      width: 0;  height: 0;  border-left: 5px solid transparent;  border-right: 5px solid transparent;
      border-top: 6px solid #5a5a5a; top: 22px;
    }
    .navbar a:hover, .dropdown:hover .dropbtn:after {    border-top: 6px solid #238aa0;  }
    .ueHeader  .dropdown .row::before{display: none;}
   .dropdown-content .megamenuLeft {  display: flex;  flex-wrap: wrap; }
  .dropdown-content .megamenuItem {  flex: 0 0 49.333%;  border-right: 1px solid #ccc; border-bottom: 1px solid #ccc;  padding: 30px 0; }
  .dropdown-content .megamenuItem:nth-child(2){ border-right:none; padding-left:25px;}
  .dropdown-content .megamenuItem:nth-child(4){ border-right:none; border-bottom: none; padding-left:25px;}
  .dropdown-content .megamenuItem:nth-child(3){   border-bottom: none;}
  .dropdown-content .megamenuItem span {    color: #12adc5;    font-size: 16px;    font-weight: 500;  display: block;  line-height: 14px;  }
  .dropdown-content .megamenuItem a {  color: #040d2d;  font-size: 40px;   font-weight: 900;   line-height: 38px;   letter-spacing: 0px;  }
  .dropdown-content .arrow {  height: 2px;  width: 5px;   position: relative;  display: inline-block;  cursor: pointer;  margin-right: 15px;  margin-bottom: 4px; 
     transition: all .4s ease;  position: relative;  top: 11px; opacity: 0; }
   .dropdown-content a:hover .arrow {  width: 30px;   margin-right: 5px;  opacity: 1; }
   .dropdown-content .arrow-black {   background: black; }
   .dropdown-content .arrow-grey {  background: #45423C; }
    .dropdown-content .arrow:before,
    .dropdown-content .arrow:after {  content: "";
      background: black;  position: absolute;  height: 2px; width: 10px;  border-radius: 30%;  }
    
    .dropdown-content .arrow:before {  right: -2px;   bottom: -3px;  transform: rotate(-45deg);  }
     .dropdown-content .arrow:after {   right: -2px;  top: -3px;  transform: rotate(45deg);  }
   .ueHeader.scrolled.noSticky .dropdown-content {   top: 59px;   }



@media screen and (max-width:1720px) {
    .ueHeader .row::before{width: 83.5%;}
    .mMenuListUl li a{font-size: 33px;}
    ul.OPLul li a{font-size: 48px;}
    .mmENCLeft h2{font-size: 56px;}

}
@media screen and (max-width:1620px) {
    ul.uemainMenu{height: 42px;}
    ul.uemainMenu li{padding: 0 16px;}
    ul.uemainMenu li a{font-size: 14px;}
    .ueHeader .row::before {width: 84.5%;bottom: 7px;}
    .HumMenu{width: 62px;height: 44px;}  
    .HHumMD{margin-bottom: -30px;}
    .linelist {width: 20px;height: 14px;}
    #HamburgerMegamenu .megamenu-header{padding: 0px 20px 42px;}
    #HamburgerMegamenu .Hamburgermm-close span {font-size: 14px;}
    #HamburgerMegamenu .Hamburgermm-close img {width: 42px;}
    #HamburgerMegamenu .megamenu-container{padding: 0px 20px 0;height: calc(100% - 135px);}
    .MainMenuList{padding: 25px 4%;}
    .mMenuListUl li a {font-size: 31px;}
    ul.OPLul li a {font-size: 45px;}
    ul.OPLul li::before{font-size: 13px;}
    .mmENContin {padding: 60px 4% 0;}
    .ENEnowInLink h6 {font-size: 15px;}
    .ENEnowInLink ul li a img{height: 52px;}
    .dropdown-content {  top: 42px;}
    .ueHeader.scrolled.noSticky .dropdown-content {    top: 53px; }
}
@media screen and (max-width:1500px) {
    .ueHeader {padding: 10px 30px 10px;left: 30px;right: 30px;}
    .ueHeader .row::before {width: 83.5%;bottom: 7px;}
    nav.nav {padding: 0 20px;box-shadow: 0 0 0 5px rgb(58 178 204 / 7%);}
    .HumMenu {width: 58px;height: 40px;}
    .ueHeader.scrolled ul.uemainMenu {height: 40px;}
    #HamburgerMegamenu .Hamburgermm-close span {font-size: 13px;}
    #HamburgerMegamenu .Hamburgermm-close img {width: 40px;}
    .mMenuListUl li a {font-size: 28px;}
    ul.OPLul li a {font-size: 42px;}
    .mmENCLeft h2 {font-size: 52px;}
    .mmENCLeft h6 {font-size: 14px;}
    .mmENCLeft a {font-size: 22px;}
    .ENEnowInLink h6 {font-size: 14px;}
    .ENEnowInLink ul li a img {height: 48px;}
    .dropdown .dropbtn:after { top: 21px;  }
    .dropdown-content{width: 650px;}
    .dropdown-content .megamenuItem a{font-size: 34px;}
    .dropdown-content .megamenuItem span{font-size: 13px;}


}
@media screen and (max-width:1400px){  
    .HMenuD{margin: 5px auto -28px;}
    .HHumMD {margin-bottom: -21px;}
    #HamburgerMegamenu .Hamburgermm-close span {font-size: 12px;}
    #HamburgerMegamenu .Hamburgermm-close img {width: 35px;}
    .mMenuListUl li a {font-size: 25px;}
    .OurProductsList{padding: 25px 4%;}
    .mmENContin {padding: 45px 4% 0;}
    ul.OPLul li::before {font-size: 12px;}
    ul.OPLul li a {font-size: 38px;}
    .mmENCLeft h6 {font-size: 13px;}
    .mmENCLeft h2 {font-size: 46px;}
    .mmENCLeft a {font-size: 20px;}
    .ENEnowInLink h6 {font-size: 13px;}
    .ENEnowInLink ul li a img {height: 44px;}
    .ueHeader.scrolled.noSticky .dropdown-content {   top: 47px;  }
    .dropdown-content .megamenuItem a {   font-size: 35px;}
    .dropdown-content .megamenuItem span {  font-size: 14px;}
    .dropdown-content {  }
}
@media screen and (max-width:1300px){
    
    .ueHeader {padding: 10px 20px 10px;left: 20px;right: 20px;}
    ul.uemainMenu {height: 38px;}
    .ueHeader .row::before {width: 84.5%;bottom: 6px;}
    .HMenuD {margin: 5px auto -28px;}
    .HHumMD {margin-bottom: -20px;}
    .dropdown-content {    top: 38px; }

    #HamburgerMegamenu { background-size: cover; }
}

@media screen and (max-width:1100px){
    .ueHeader {padding: 10px 15px 10px;left: 10px;right: 10px;}
    ul.uemainMenu li {padding: 0 10px;}
    #HamburgerMegamenu{background-size: cover;}
    #HamburgerMegamenu .megamenu-container{width: 100%;}
    .mMenuListUl li a {font-size: 23px;}
    ul.OPLul li a {font-size: 35px;}
    .mmENCLeft h2 {font-size: 43px;}

}

@media screen and (max-width:800px){
    .ueHeader{padding: 6px 10px;left: 0;right: 0;position: absolute;}
    .HMenuD{display: none;}
    .HHumMD{display: none;}
    .ueHeader .row::before{opacity: 0;visibility: hidden;}
    .HLogoD {width: 100%;}
    .MainMenuList {padding: 25px 2%;}
    #HamburgerMegamenu .megamenu-header a {width: 22vw;}
    .mMenuListUl li a {font-size: 19px;}
    .OurProductsList {padding: 25px 2%;}
    ul.OPLul li a {font-size: 30px;}
    .mmENContin {padding: 45px 2% 0;}
    .HLogoD img {width: 31%;}

}
@media screen and (max-width:550px){
    #HamburgerMegamenu .mm-main-container{padding: 20px}
    #HamburgerMegamenu .megamenu-header a {width: 25vw;}
    #HamburgerMegamenu .Hamburgermm-close img {width: 18px;}
    #HamburgerMegamenu .Hamburgermm-close span {font-size: 11px;line-height: 1;}
    #HamburgerMegamenu .megamenu-header {padding: 0px 0px 20px;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
    #HamburgerMegamenu .Hamburgermm-close{right: 0;}
    .mMenuListUl{padding-left: 15px;}
    .MainMenuList {padding:20px 0%;display: block;border-top: 0px solid rgb(255 255 255 / 11%);}
    .OurProductsList {padding: 19px 0%;}
    ul.OPLul li{padding-top: 0;}
    ul.OPLul li::before{opacity: 0;visibility: hidden;}
    ul.OPLul{display: block;padding-top: 20px;position: relative;border-left: 1px solid rgb(255 255 255 / 11%);padding-left: 15px;}
    ul.OPLul::before {content: 'Our Products';position: absolute;color: #fff;top: 0;left:15px;font-size: 14px;text-transform: uppercase;opacity: 0.8;}
    ul.OPLul li a {font-size: 28px;line-height: 1.2;}
    .mMenuListUl li a {font-size: 16px;line-height: 1.8;}
    .mmENContin {padding: 25px 0% 0;display: block;}
    .mmENCLeft h6 {font-size: 12px;margin-bottom: 3px;}
    .mmENCLeft h2 {font-size: 28px;}
    .mmENCRight {padding-top: 30px;}
    .ENEnowInLink h6{text-align: left;}
    .ENEnowInLink ul{-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
    #HamburgerMegamenu .megamenu-container{overflow-y: scroll;overflow-x: hidden;}
    .ueHeader.isSticky{-webkit-transform: translateY(0%);-ms-transform: translateY(0%);transform: translateY(0%);}
    .ueHeader.noSticky{-webkit-transform: translateY(0%);-ms-transform: translateY(0%);transform: translateY(0%);}
    .ENEnowInLink a img {height: 45px;}
    .ENEnowInLink a{text-align: left;}

}
@media screen and (max-width:420px){
    #HamburgerMegamenu .megamenu-container{padding: 0px 0px 0;}
    .mMenuListUl li a {font-size: 15px;line-height: 1.7;}
    ul.OPLul li a {font-size: 25px;line-height: 1.2;}
    .mmENCLeft h2 {font-size: 24px;}
    .mmENCLeft a {font-size: 17px;}


}

