
.HomePage section{position: relative;}
.UeHBanSec{position: relative;width: 100%;}
.UeHSecImg{position: relative;width: 100%;overflow: hidden;}
.UeHSecImg img.HSecBgs{position: relative;display: block;height: 100%;-webkit-transition: all 15s ease;-o-transition: all 15s ease;transition: all 15s ease;-webkit-transform: scale(1.4);-ms-transform: scale(1.4);transform: scale(1.4);}
.in-viewport .UeHSecImg img.HSecBgs{-webkit-transform: scale(1);-ms-transform: scale(1);transform: scale(1);}
.UeHBanImg .Hbanbefore{content:"";position: absolute;width: 35%;height: auto;bottom: 0;right: 14%;z-index: 9;}
.UeHBanImg .Hbanbefore img{position: relative;width: 100%;height: 100%;display: block;}
.UeHBanCont{position: absolute;top:50%;left: 14%;-webkit-transform: translate(0px, -50%);-ms-transform: translate(0px, -50%);transform: translate(0px, -50%);}
.UeHBanCont h6{font-size: 24px;line-height: 1;margin: 0;}
.UeHBanCont h1{font-family: 'halyard_textblack';color: #3ab2cc;font-size: 280px;line-height: 0.9;margin:-45px 0 -10px -18px;letter-spacing: -10px;}
.UeHBanCont h1 span{padding-right: 7px;}
.UeHBanCont h3{position: relative;font-family: 'halyard_textblack';color: #000000;font-size: 74px;line-height: 1;letter-spacing: -2px;margin:0 0 7px -7px;padding-left: 105px;}
.UeHBanCont h3 .EBig{display: block;position: absolute;left:0;top:0;-webkit-transform: translateX(-100%);-ms-transform: translateX(-100%);transform: translateX(-100%);font-family: 'halyard_textblack';color: #000000;font-size: 182px;line-height:0.75;letter-spacing: -2px;margin: 0 0 0 -4px;}
.UeHBanCont h3 span.hidE{display: none;}
.UeHBanCont h3 span.dropinAnimi {position: relative;}
.UeHBanCont p{margin: 0;}
.secTwo {}
.secTwo:before {content: 'Affordable to Every student';position: absolute;font-size: 20px;color:#fff;text-transform: uppercase;bottom: 20px;right: 20px;z-index: 9;font-weight: 500;line-height: 1;}

.UeHIntroCont{position: absolute;top:40%;left: 12%;right:12%;text-align: center;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-transform: translate(0px, -50%);-ms-transform: translate(0px, -50%);transform: translate(0px, -50%);z-index: 9;}
.UeHIntroCont h6{font-size: 16px;line-height: 1;margin: 0;color: #fff;text-transform: uppercase;position: relative;}
.UeHIntroCont h6:before{content: "";position: absolute;width: 100%;height: 2px; bottom: -3px;left:0;background: #fff;}
.UeHIntroCont h2{font-family: 'halyard_textblack';color: #fff;font-size: 76px;letter-spacing: -3px;font-weight: 400;margin: 0;}
.UeHIntroCont p{color: #fff;margin: 0;font-size: 112%;}
.UeHIntroCont p.exprbtn{width: 60%;margin:40px 0;position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.UeHIntroCont p.exprbtn:before{content: "";position: absolute;width: 100%;height: 2px;top: 50%;left: 0;background: rgb(255 255 255 / 22%);}
.UeHIntroCont p.exprbtn a{position: relative; background: #3ab2cc;color: #fff;display: block;line-height: 45px;border-radius: 30px;padding: 0 30px;font-size: 20px;font-weight: 600;box-shadow: 0 0 0 7px rgb(255 255 255 / 7%);}
.UeHIntroCont p.exprbtn a:hover{background: #ffffff;color: #3ab2cc;}

.UeHIntroSec .staranimi{position: absolute;top:40%;right: 20%;width: 4px;height: 4px;background: #fff;border-radius: 50%;box-shadow:0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 6px rgba(255, 255, 255, 0.1) ;opacity: 0.7;-webkit-animation: startanime 3s linear infinite;animation: startanime 3s linear infinite;}
.UeHIntroSec .staranimi::before{content: ''; position: absolute;top: 50%;width: 300px;height: 1px;transform: translateY(-50%);background: linear-gradient(90deg, #fff , transparent );}
@-webkit-keyframes startanime{
    0%{-webkit-transform: rotate(315deg) translateX(0);transform: rotate(315deg) translateX(0);opacity: 0;}
    50%{-webkit-transform: rotate(315deg) translateX(-300px);transform: rotate(315deg) translateX(-300px);opacity: 0.9;}
    100%{-webkit-transform: rotate(315deg) translateX(-600px);transform: rotate(315deg) translateX(-600px);opacity: 0;}
}
@keyframes startanime{
    0%{-webkit-transform: rotate(315deg) translateX(0);transform: rotate(315deg) translateX(0);opacity: 0;}
    50%{-webkit-transform: rotate(315deg) translateX(-300px);transform: rotate(315deg) translateX(-300px);opacity: 0.9;}
    100%{-webkit-transform: rotate(315deg) translateX(-600px);transform: rotate(315deg) translateX(-600px);opacity: 0;}
}


.UeHSecImg .UeHBeforeImg{position: absolute;width:38%;height:auto;bottom: 0;left: 51%;-webkit-transform: translate(-50%, 0px);-ms-transform: translate(-50%, 0px);transform: translate(-50%, 0px);z-index: 9;}
.UeHSecImg .UeHBeforeImg img{position: relative;width:100%;height:100%;display: block;}
.HSecConts{position: absolute;width: 100%;height: 100%;top: 0;left: 0;z-index: 9;}
.HSecConts .container{position: relative;height: 100%;}
.HSContBox{display: -webkit-box;display: -ms-flexbox;display: flex;width: 114%;margin: 0 -7%;margin-top:18%;}
.TitleLeftDiv{margin-right: auto;}
.TitleRightDiv{margin-left: auto;margin-top: 35px;}
.HProTitles {position: relative;}
.HProTitles h5{font-size: 30px;line-height: 1;margin: 0;color: #191f23;}
.HProTitles h2{font-family: 'halyard_textblack';color: #3ab2cc;font-size: 156px;line-height: 0.8;margin: -20px 0 10px -12px;letter-spacing: -5px;}
.HProTitles h3{font-family: 'halyard_textblack';color: #000000;font-size: 96px;letter-spacing: -3px;line-height: 1;margin:0 0 10px -6px;}
.HProTitles h6{text-align: right;font-size: 22px;}
.HProTitles p{margin: 0;}
.HProTitles p.HWebViewCont {display: block;}
.HProTitles p.HMobViewCont {display: none;}
p.exprbtn{margin-top: 20px;display: -webkit-box;display: -ms-flexbox;display: flex;position: relative;}
p.exprbtn a{position: relative;display: block;border: 1px solid transparent; background: #3ab2cc;color: #fff;display: block;line-height: 45px;border-radius: 30px;padding: 0 30px;font-size: 20px;font-weight: 600;box-shadow: 0 0 0 7px rgb(255 255 255 / 7%);display: block;}
p.exprbtn a:hover{background: #fff;color: #3ab2cc;border-color: #3ab2cc;}

.UeHKampusSec .HProTitles h2{color: #040d2d;}
.UeHKampusSec .UeHSecImg .UeHBeforeImg{width: 31%;}
.UeHUstudySec .UeHSecImg .UeHBeforeImg{width: 60%;left: 36.5%;}
.ksmaccbefore{position: absolute;width:29%;height:auto;bottom: 0;left:48%;-webkit-transform: translate(-50%, 0px);-ms-transform: translate(-50%, 0px);transform: translate(-50%, 0px);z-index: 9;}
.ksmaccbefore img{position: relative;width:100%;height:100%;display: block;}
.maccIcon{position: absolute;}
.maccIcon.iconOne{width: 195px;top: 0;left: 0;-webkit-transform:translate(-43%, -43%);-ms-transform: translate(-43%, -43%);transform: translate(-43%, -43%);}
.maccIcon.icontwo{width: 185px;top: 0;right: 0;-webkit-transform:translate(37%, -48%);-ms-transform: translate(37%, -48%);transform: translate(37%, -48%);}
.maccIcon.iconThree{width: 200px;bottom: 0;right: 0;-webkit-transform: translate(120%, -48%);-ms-transform: translate(120%, -48%);transform: translate(120%, -48%);}
.UstudyTitles .HProTitles h5{color:#ffffff;}
.UstudyTitles .HProTitles h2{color:#ffffff;}
.UstudyTitles .HProTitles h6{color:#ffffff;}
.UstudyTitles .HProTitles.TitleLeftDiv p{color:#ffffff;}


.HVerticalSec .container{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.HSVContBox{position: relative; width: 100%;background: rgb(37 151 184 / 90%);padding:100px 70px 150px 70px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.HSVContBox:before{content: '';position: absolute;width:45%;height:23%;right:70px;bottom: 0;-webkit-filter: brightness(0) invert(1);filter: brightness(0) invert(1);opacity: 0.4;background: url(../../asstes/images/Ueducate-VerIcon.webp) no-repeat center bottom;background-size: 100%;}
.HSVCol {width:50%}
.HSVContTitle {color: #fff;padding-left: 0px;}
.HSVContTitle h2{color: #fff;font-family: 'halyard_textblack';font-size: 138px;line-height: 1;margin: 0px 0 0 -28px;}
.HSVContTitle h6{font-size: 22px;margin-bottom: 25px;margin-top: 5px;}
.HSVContTitle p.exprbtn a{background: #fff;color: #040d2d;border-color: #fff;}
.HSVContTitle p.exprbtn a:hover{background: #040d2d;color: #ffffff;border-color: #fff;}
.HSVLists{margin: 0;}
.HSVLists ul{background: rgb(30 123 156 / 66%);border-top: 1px solid #50bdd2;border-bottom: 1px solid #50bdd2;}
.HSVLists ul li{border-bottom: 1px solid #50bdd2;}
.HSVLists ul li:last-child{border-bottom: 0px solid #50bdd2;}
.HSVLists ul li a{padding: 15px 20px;color: #ffffff;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;position: relative;}
.HSVLists ul li a::before{content: '';position: absolute;width: 32px;height: 14px;top: 50%;right:20px;-webkit-transform: translate(0px, -50%);-ms-transform: translate(0px, -50%);transform: translate(0px, -50%);background: url(../../asstes/images//Verti-ArrowRightIcon.png) no-repeat center center;background-size: 100%;}
/* .HSVLists ul li:hover{background: #1a7795;} */
.HSVLists ul li .vulicons{width: 48px;height: 48px;}
.HSVLists ul li:nth-child(1) .vulicons{background: url(../../asstes/images/VerticalLIconOne.webp) no-repeat center center;background-size: 100%;}
.HSVLists ul li:nth-child(2) .vulicons{background: url(../../asstes/images/VerticalLIconTwo.webp) no-repeat center center;background-size: 100%;}
.HSVLists ul li:nth-child(3) .vulicons{background: url(../../asstes/images/VerticalLIconThree.webp) no-repeat center center;background-size: 100%;}
.HSVLists ul li:nth-child(4) .vulicons{background: url(../../asstes/images/VerticalLIconFour.webp) no-repeat center center;background-size: 100%;}
.HSVLists ul li:nth-child(5) .vulicons{background: url(../../asstes/images/VerticalLIconFive.webp) no-repeat center center;background-size: 100%;}
.HSVLists ul li strong{font-family: 'halyard_textblack';font-size: 24px;line-height: 1;display: block;margin: 0 15px;flex: 0;}
.HSVLists ul li small{display: block;width: 100%;padding-right: 30px;}

.HapClientSec{padding: 150px 0 100px;background: rgb(191,235,252);background: linear-gradient(0deg, rgba(191,235,252,1) 0%, rgba(159,220,242,1) 100%);}
.HHCTitle{margin-bottom: 120px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.HHCTitle h2{margin: -70px 20px 0 0px;color: #231f20; font-family: 'halyard_textblack';font-size: 170px;line-height: 1;letter-spacing: -5px;position: relative;}
.HHCTitle h2::before{content: 'Customers';position: absolute;bottom: -18px;left: 10px;font-size: 28px;color: #191f23;font-family: 'Poppins', sans-serif;letter-spacing: -1px;}
.HHCTitle p{margin: 0;}
.HCSliderDiv{overflow: hidden;}
.HCSliderDiv .slick-list{width: 110%;margin: 0 -5%;}
.HCSliders{margin-bottom: 150px;}
.hcitems{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.hcitems .hcIcons{margin: 0%;}
.hcitems .hcIcons img{display: block;width: 120px;}
.hcConts{padding-left: 15px;}
.hcConts h3{font-size: 34px;color: #231f20;font-family: 'halyard_textblack';letter-spacing: -1px;margin: 0;}
.hcConts p{margin-bottom: 20px;}
.hcConts h4{margin: 0;font-size: 18px;color: #191f23;font-weight: 600;}
.HCSliderDiv p.exprbtn{margin-bottom: 0;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.HCSliderDiv p.exprbtn::before{content: '';position: absolute;width: 60%;height: 2px;left: 20%;right: 20%;top: 50%;background: #c3c6c7;}
.star-rating {display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient: horizontal;-webkit-box-direction: reverse;-ms-flex-direction: row-reverse;flex-direction: row-reverse;font-size:1.5em;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;padding:0;text-align:center;width:5em;}
.star-rating input {display:none;}
.star-rating label {color:#ccc;cursor:pointer;}
.star-rating :checked ~ label {color:#f90;}
.star-rating label:hover,  .star-rating label:hover ~ label {    color:#fc0;  }

.HomePage .HapClientSec{background: url(../../asstes/images/HHapCliBgImg.jpg) no-repeat center center;background-size:100% ;}
.HomePage .HHCTitle h2{color: #3ab2cc;}
.HomePage .hcConts h3{color: #3ab2cc;}



.HNewsSec{padding: 180px 0;background: url(../../asstes/images/HNewsSecBg.jpg) no-repeat center center;background-size: 100%;}
.HNewsSec .row{-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.HNewsSec .row .col:nth-child(1){width: 28%;flex: none;}
.ComTitle{position: relative;}
.ComTitle h2{font-family: 'halyard_textblack';color: #1f96b0;font-size: 72px;line-height: 0.9;margin-bottom: 25px;}
.ComTitle p.exprbtn{margin: 50px 0 0;}
.HNewsItems{display: -webkit-box;display: -ms-flexbox;display: flex;}
.HNewsItem{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;padding: 0 15px;}
.HNIImg{border-radius: 15px;overflow: hidden;}
.HNIImg img{display: block;}
.HNICont{margin: 0;}
.HNICont h3{font-family: 'halyard_textblack';color: #3ab2cc;font-size: 29px;line-height: 1;margin: 15px 0;}
.HNICont p{margin: 0;}

body .HomePage .aos-animate{opacity: 0;-webkit-transform: inherit;transform: inherit;transition-property: opacity,-webkit-transform;transition-property: opacity,transform;transition-property: opacity,transform,-webkit-transform;}
body .HomePage .in-viewport .aos-animate{opacity: 1;-webkit-transform: translateZ(0);transform: translateZ(0);}

@media screen and (min-width:801px) {  
    .AnimiOFlow{ overflow: hidden;}
    .drop-in {display: block;position: relative;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;opacity: 0;-webkit-transform: translateY(-100%);transform: translateY(-100%);}
    .in-viewport .drop-in {opacity: 1;-webkit-transform: translateY(0%);transform: translateY(0%);}
    .in-viewport .AnimiDelay1 {transition-delay: 1s;}
    .in-viewport .AnimiDelay2 {transition-delay: 1.5s}
    .in-viewport .AnimiDelay3 {transition-delay: 2s}
    .dropinAnimi{display: block; -webkit-animation: dropin 1.5s ease backwards;animation: dropin 1.5s ease backwards;}
    .dropinAnimiDelay{animation-delay: 0.5s;}
    .dropinAnimiDelay1{animation-delay: 1s;}
    .dropinAnimiDelay2{animation-delay: 1.5s;}

    @-webkit-keyframes dropin {
        from {
            opacity: 0;
            -webkit-transform: translateY(-100%);
                    transform: translateY(-100%);
        }
        to {
            opacity: 1;
            -webkit-transform: translate(0%);
                    transform: translate(0%);
        }
    }
    @keyframes dropin {
        from {
            opacity: 0;
            -webkit-transform: translateY(-100%);
                    transform: translateY(-100%);
        }
        to {
            opacity: 1;
            -webkit-transform: translate(0%);
                    transform: translate(0%);
        }
    }
}
@media screen and (max-width:1800px) {
    .UeHBanCont h1{font-size: 260px;}
    .UeHBanCont h3{font-size: 70px;}
    .UeHBanCont h3 .EBig{top: -6px;}
    .UeHIntroCont h2{font-size: 72px;}
    .UeHIntroCont p.exprbtn a{font-size: 18px;}
    .HProTitles h5{font-size: 28px;}
    .HProTitles h2{font-size: 150px;}
    .HProTitles h3{font-size: 90px;}
    p.exprbtn a{font-size: 18px;}
    .HSVContTitle h2,.HHCTitle h2{font-size: 150px;}
    .HHCTitle h2::before{font-size: 26px;}
    .hcConts h3{font-size: 30px;}
    .ComTitle h2{font-size: 62px;}

}

@media screen and (max-width:1720px) {
    .UeHSecImg .UeHBeforeImg{width: 36%;left: 50%;}
    .UeHBanCont h1 {font-size: 240px;}
    .UeHBanCont h3 {font-size: 65px;padding-left: 96px;}
    .UeHBanCont h3 .EBig{font-size: 166px;}
    .HProTitles h5 {font-size: 24px;}
    .HProTitles h2 {font-size: 134px;}
    .HProTitles h3 {font-size: 80px;}
    .HProTitles h6 {font-size: 20px;}
    .UeHIntroCont p.exprbtn a,p.exprbtn a {font-size: 17px;}
    .HSVContTitle h2, .HHCTitle h2 {font-size: 116px;}
    .HHCTitle h2::before {font-size: 22px;}
    .hcConts h3 {font-size: 28px;}
    .HCSliders {margin-bottom: 130px;}
    .HNewsSec {padding: 140px 0;}
    .maccIcon.iconOne {width: 175px;}
    .maccIcon.icontwo {width: 160px;}
    .maccIcon.iconThree {width: 182px;}
    .HSVContTitle {padding-left: 30px;}
    .HSVContTitle h6 {font-size: 20px;}
    .HSVLists ul li .vulicons {width: 46px;height: 46px;}
    .HSVLists ul li strong{font-size: 22px;margin: 0 10px;} 
    .HSVContBox{padding: 90px 50px 130px 50px;}
    .HSVCol.HSVContTitle{width: 45%;}
    .HSVCol.HSVLists{width: 55%;}
    .HapClientSec {padding: 120px 0 90px;}
    .UeHIntroCont h2 {font-size: 64px;}
    .HSContBox{width: 118%;margin: 0 -9%;margin-top: 18%;}
    .TitleRightDiv{width: 31%}
    .TitleRightDiv p br{display: none;}
    .HNICont p br{display: none;}
}

@media screen and (max-width:1620px) {
    .UeHBanCont h1 {font-size: 225px;margin: -38px 0 -6px -18px;}
    .UeHBanCont h3 {font-size: 56px;padding-left: 84px;}
    .UeHBanCont h3 .EBig {font-size: 146px;}
    .UeHBanCont h6 {font-size: 20px;}
    .UeHIntroCont h2 {font-size: 62px;}
    .UeHIntroCont h6 {font-size: 15px;}
    .UeHIntroCont p.exprbtn a, p.exprbtn a {font-size: 16px;}
    .UeHSecImg .UeHBeforeImg {width: 35%;left: 51%;}
    .HProTitles h5 {font-size: 23px;}
    .HProTitles h2 {font-size: 124px;margin: -16px 0 10px -12px;}
    .HProTitles h3 {font-size: 78px;}
    .HProTitles h6 {font-size: 18px;}
    .maccIcon.iconOne {width: 162px;}
    .maccIcon.icontwo {width: 146px;}
    .maccIcon.iconThree {width: 155px;}
    .HSVContTitle h2 {font-size: 104px;}
     .HHCTitle h2 {font-size: 122px;}
    .HSVContTitle h2{margin: 0px 0 0 -21px;}
    .HSVLists ul li a {padding: 12px 17px;}
    .HHCTitle {margin-bottom: 90px;}
    .HCSliders {margin-bottom: 95px;}
    .hcConts h3 {font-size: 26px;}
    .hcConts h4{font-size: 17px;}
    .HapClientSec {padding: 100px 0 80px;}
    .HNewsSec {padding: 120px 0;}
    .ComTitle h2 {font-size: 60px;}
    .HNICont h3{font-size: 26px;}
    .secTwo:before{font-size: 18px;}
}

@media screen and (max-width:1500px){
    .UeHBanCont h6 {font-size: 18px;}
    .UeHBanCont h1 {font-size: 205px;margin:-34px 0 -6px -13px;}
    .UeHBanCont h3 {font-size: 48px;padding-left: 72px;margin: 0 0 7px -4px;}
    .UeHBanCont h3 .EBig {font-size: 126px;}
    .UeHIntroCont h6 {font-size: 14px;}
    .UeHIntroCont h2 {font-size: 54px;line-height: 1.1;letter-spacing: -2px;}
    .UeHIntroCont p.exprbtn{margin: 30px 0;}
    .UeHIntroCont p.exprbtn a, p.exprbtn a {font-size: 15px;line-height: 38px;padding: 0 25px;}
    .UeHIntroCont{top: 43%;}
    .HSContBox {margin-top: 15%;}
    .HProTitles h3 {font-size: 70px;}
    .HProTitles h6 {font-size: 16px;}
    .HProTitles h5 {font-size: 21px;}
    .maccIcon.iconOne {width: 144px;}
    .maccIcon.icontwo {width: 130px;}
    .maccIcon.iconThree {width: 140px;}
    .HProTitles h2 {font-size: 120px;margin: -16px 0 10px -7px;}
    .HSVContTitle h2{font-size: 82px;}
     .HHCTitle h2 {font-size: 105px;}
    .HSVContTitle h2 {margin: 0px 0 0 -18px;}
    .HSVContTitle h6 {font-size: 18px;margin-bottom: 12px;}
    .HSVLists ul li .vulicons {width: 35px;height: 35px;}
    .HSVLists ul li strong {font-size: 20px;margin: 0 7px;}
    .HSVLists ul li a::before{width: 22px;height: 10px;}
    .HSVContBox {padding: 85px 50px 110px 50px;}
    .ComTitle h2 {font-size: 50px;}
    .secTwo:before{font-size: 17px;}
    .TitleRightDiv{    margin-top: 24px;}

}
@media screen and (max-width:1400px){
    .UeHBanCont h6 {font-size: 17px;}
    .UeHBanCont h1 {font-size: 175px;margin:-28px 0 -2px -12px;}
    .UeHBanCont h3 {font-size: 40px;padding-left: 60px;margin: 0 0 7px -4px;}
    .UeHBanCont h3 .EBig {font-size: 107px;}
    .UeHIntroCont h6 {font-size: 13px;}
    .UeHIntroCont h2 {font-size: 52px;}
    .UeHIntroCont p.exprbtn a, p.exprbtn a {font-size: 14px;line-height: 36px;padding: 0 22px;}
    ul.uemainMenu li a {font-size: 13.5px;}
    .ueHeader .row::before {width: 82.5%;bottom: 6px;}
    .maccIcon.iconOne {width: 120px;}
    .maccIcon.icontwo {width: 120px;}
    .maccIcon.iconThree {width: 130px;}
    .HSVLists ul li a {padding: 10px 14px;}
    .HSVLists ul li a::before{right: 8px;}
    .HSVContTitle h2{font-size: 85px;}
    .HHCTitle h2 {font-size: 95px;}
    .HSVContTitle h6 {font-size: 16px;margin-bottom: 10px;}
    .HSVCol.HSVContTitle p.exprbtn{margin-bottom: 0;}
    .HSVLists ul li .vulicons {width: 32px;height: 32px;}
    .HHCTitle h2::before {font-size: 19px;}
    .hcConts h3 {font-size: 24px;}
    .hcConts p {margin-bottom: 15px;}
    .hcitems .hcIcons img {width: 95px;}
    .hcConts h4 {font-size: 15px;}
    .HHCTitle {margin-bottom: 75px;}
    .HCSliders {margin-bottom: 70px;}
    .HapClientSec {padding: 90px 0 70px;}
    .ComTitle p.exprbtn {margin: 30px 0 0;}
    .HNICont h3 {font-size: 22px;margin: 10px 0;}
    .HSContBox{width: 120%;margin: 0 -10%;margin-top:13%;}
    .HSVLists ul li small{padding-right: 15px;}
    .secTwo:before{font-size: 15px;}
}
@media screen and (max-width:1300px){
    .HProTitles h5 {font-size: 18px;}
    .UeHBanCont h6 {font-size: 16px;}
    .UeHBanCont h1 {font-size: 165px;letter-spacing: -5px;margin: -28px 0 -2px -12px;}
    .HProTitles h2 {font-size: 112px;margin: -16px 0 10px -7px;}
    .HProTitles h3 {font-size: 50px;}
    .HProTitles h6 {font-size: 15px;}
}
@media screen and (max-width:1200px){
    .UeHIntroCont h2 {font-size: 42px;}
    .HSContBox {width: 116%;margin: 0 -8%;margin-top: 13%;}
}
@media screen and (max-width:1100px){
    .UeHBanCont h6 {font-size: 15px;}
    .UeHBanCont h1 {font-size: 134px;letter-spacing: -2px;margin: -20px 0 -2px -10px;}
    .UeHBanCont h3 {font-size: 30px;padding-left: 45px;margin: 0 0 7px -4px;letter-spacing: -0.5px;}
    .UeHBanCont h3 .EBig {font-size: 80px;}
    .UeHIntroCont h2 {font-size: 38px;letter-spacing: -1px;}
    .UeHIntroCont p br{display: none;}
    .UeHIntroCont p.exprbtn {margin: 24px 0;}
    .UeHIntroCont {top: 40%;}
    .HProTitles h2 {font-size: 100px;margin: -10px 0 10px -7px;}
    .HProTitles h6 {font-size: 14px;}
    .HProTitles h3 {font-size: 45px;}
    .HSVContBox {padding: 65px 35px 90px 35px;}
    .HSVContTitle h2{font-size: 78px;}
    .HSVContTitle h6 {font-size: 15px;margin-bottom: 10px;}
    .HSVCol.HSVContTitle {width: 42%;padding-left: 15px;}
    .HSVCol.HSVLists {width: 58%;}
    .HHCTitle h2 {font-size: 80px;}
    .HHCTitle h2::before {font-size: 15px;}
    .HapClientSec {padding: 90px 0 70px;background-size: cover;}
    .HHCTitle {margin-bottom: 40px;}
    .HSVLists ul li a {padding: 10px 10px;}
    .HSVLists ul li strong {font-size: 18px;margin: 0 6px;}
    .maccIcon.iconOne {width: 100px;}
    .maccIcon.icontwo {width: 94px;}
    .maccIcon.iconThree {width: 98px;}
    .HCSliders {margin-bottom: 50px;}
    .HNewsSec {padding: 65px 0;}
    .HNICont p br{display: none;}
    .HSContBox {width: 105%;margin: 0 -2.5%;margin-top: 8%;}

}


@media screen and (max-width:800px){
    .HSVContTitle h2 {font-size: 60px;}
}


@media screen and (max-width:550px){

    .HomePage{overflow-x: hidden;}
    .MobSecsAnimi{height: 400vh;position: relative;}
    .MobSecsAnimi section{position: absolute; width: 100%;height: 100vh;overflow: hidden;clip: rect(0, auto, auto, 0);}
    .MobSecsAnimi section:nth-child(2){top:100vh;}
    .MobSecsAnimi section:nth-child(3){top:200vh;}
    .MobSecsAnimi section:nth-child(4){top:300vh;}
    .InSecMobAnimi{overflow: hidden;position: fixed;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);width: 100%;}
    [data-aos] {pointer-events: auto !important;}
    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {opacity: 1 !important;}
    html:not(.no-js) [data-aos=fade-up] {transform: none !important;}
    .aos-animate {
        /*CSS transitions*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        /*CSS transforms*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
    [data-aos^="fade"][data-aos^="fade"],
    [data-aos^="zoom"][data-aos^="zoom"] {
        opacity: 1 !important;
    }
    [data-aos^=fade][data-aos^=fade]{transform: translate3d(0,0,0)!important;}
    [data-aos="zoom-in"] {
        transform: scale(1) !important;
    }


    .UeHBanSec .UeHSecImg{background: url(../../asstes/images/hMobBanImg.jpg) no-repeat center bottom; background-size: cover;}
    .UeHBanSec .UeHSecImg>img.HSecBgs{opacity: 0;}
    .UeHSecImg img.HSecBgs{object-fit: cover;-webkit-transform: scale(1);-ms-transform: scale(1);transform: scale(1);}
    .UeHSecImg{height: 100vh;}
    .UeHBanCont h3 .EBig {top: 4px;}
    .UeHBanImg .Hbanbefore{width: 100%;right: -3%;}
    .UeHBanCont{top: 25%;left: 5%;right: 5%;}
    .UeHBanCont h1{font-size: 105px;margin: -16px 0 -2px -6px;}
    .UeHBanCont h1 span{display: block;}
    .UeHBanCont h6 {font-size: 19px;}
    .UeHBanCont h3{margin: 3px 0 5px -1px;}
    .UeHBanCont p br{display: none;}
    .UeHBanCont p {font-weight: 500;}
    .UeHIntroCont{left: 6%;right: 6%;}
    .UeHIntroCont h2 {font-size: 34px;letter-spacing: -1px;margin-top: 10px;}
    .UeHIntroCont h2 br{display: none;}
    .HSContBox{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;margin-top: 0;position: relative;top: 4%;padding:0 10px;   width: 100%;margin: 0 0%;}
    .HProTitles{width: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
    .HProTitles h2{text-align: center;margin: -10px 0 10px 0px;position: relative;}
    .HProTitles h2:before{content: "The";position: absolute;line-height: 1;margin: 0;color: #191f23;font-size: 18px;font-family: 'Poppins', sans-serif;letter-spacing: 0;top: -6px;left: 7px;}
    .HProTitles h2:after{content: "";position: absolute;text-align: right;line-height: 1.1;margin: 0;color: #191f23;font-size: 18px;font-family: 'Poppins', sans-serif;letter-spacing: 0;bottom:0;right:0;-webkit-transform: translateY(112%);-ms-transform: translateY(112%);transform: translateY(112%);}
    .UeHUstudySec .HProTitles h2:after{color: #fff;}
    .UeHUstudySec .TitleRightDiv p{color: #fff;}
    .UeHUstudySec .TitleRightDiv.HProTitles h3{color: #fff;}
    .UeHUstudySec .HProTitles h2:before{color: #fff;}
    .HProTitles p br{display: none;}
    .UeHUstudySec .UeHSecImg .UeHBeforeImg {width: 135%;left: 25.5%;}
    .UeHKatbookSec .HProTitles h2:after{content: "of education";}
    .UeHKampusSec .HProTitles h2:after{content: "of Educational Institution Management Software";}
    .HProTitles p{text-align: center;}
    .HProTitles p.HWebViewCont {display: none;}
    .HProTitles p.HMobViewCont {display: block;}
    .HProTitles h5 {display: none;}
    .HProTitles h3{margin-bottom: 6px;}
    .HProTitles h6 {font-size: 14px;opacity: 0;visibility: hidden;}
    .UeHSecImg .UeHBeforeImg {width: 100%;left: 50%;}
    .TitleRightDiv {margin-top: 15px;}
    .UeHKampusSec .UeHSecImg .UeHBeforeImg {width: 100%;bottom: -19%;}
    .ksmaccbefore{width: 63%;}
    .maccIcon.iconOne{width: 85px;-webkit-transform: translate(-48%, 44%);-ms-transform: translate(-48%, 44%);transform: translate(-48%, 44%);}
    .maccIcon.icontwo {width: 90px;-webkit-transform: translate(75%, -35%);-ms-transform: translate(75%, -35%);transform: translate(75%, -35%);}
    .maccIcon.iconThree{-webkit-transform: translate(80%, -45%);-ms-transform: translate(80%, -45%);transform: translate(80%, -45%);}
    .HProTitles h2:after{content: 'of Secured Closed Communication';}
    .HVerticalSec .container{padding: 0;}
    .HVerticalSec .UeHSecImg{display: none;}
    .HVerticalSec .HSecConts{background: url(../../asstes/images/HVerticalBg.jpg) no-repeat center center;background-size: cover;position: relative;}
    .HSVContBox{padding: 0px 0px 0px 0px;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
    .HSVCol.HSVContTitle {width: 100%;padding-left: 50px;padding-top: 30px;padding-bottom: 30px;}
    .HSVCol.HSVLists {width: 100%;padding: 15px 30px 30px;background-color: rgb(30 123 156 / 66%);}
    .HSVContTitle h2 {margin: 0px 0 0 -12px;}
    .HSVLists ul {background: transparent;border-top: 0px solid #50bdd2;}
    .HSVLists ul li a{-ms-flex-wrap: wrap;flex-wrap: wrap;}
    .HSVLists ul li small{width: 80%;}
    .HSVLists ul li .vulicons {width: 45px;height: 45px;}
    .HSVContBox:before{opacity: 0;pointer-events: none;}
    .HSVLists ul li strong {font-size: 32px;margin: 0 0 0 6px;width: calc(100% - 65px);    }
    .HapClientSec {padding: 40px 0;}
    .HHCTitle{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
    .HHCTitle h2 {margin: 0px 0px 30px 0px;}
    .HHCTitle p {text-align: center;}
    .HCSliders {padding-left: 10px;}
    .HCSliderDiv .slick-list {width: 150%;margin: 0 0% 0 0;}
    .hcitems{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
    .HCSliderDiv p.exprbtn::before{width: 100%;left: 0%;right: 0%;opacity: 0.5;}
    .HNewsSec {padding: 40px 0;background: #fff;}
    .HNewsSec .row .col:nth-child(1){width: 100%;}
    .HNewsSec .ComTitle{text-align: center;margin-bottom: 26px;}
    .HNewsSec .ComTitle h2{letter-spacing: -1px;}
    .HNewsSec .ComTitle h2 br{display: none;}
    .HNewsSec .ComTitle p br{display: none;}
    .HNewsSec .ComTitle p.exprbtn{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
    .HNewsItems {width: 100%;overflow-x: scroll;overflow-y: hidden;white-space: nowrap;padding-bottom: 8px;}
    .HNewsItem {-webkit-box-flex: 0;-ms-flex: 0 0 80%;flex: 0 0 80%;white-space: pre-wrap;}
    .ComTitle h2 {font-size: 44px;margin-bottom: 15px;}
    .ComTitle p.exprbtn {margin: 0px 0 0;}
    .UeHIntroSec .staranimi{top:52%;}
}

@media screen and (max-width:420px){
    body {font-size: 13.5px;}
    .UeHBanCont h6 {font-size: 16px;}
    .UeHBanCont h1 {font-size: 94px;}
    .UeHBanCont h3{font-size: 25px;padding-left: 37px;}
    .UeHBanCont h3 .EBig {font-size: 64px;}
    .UeHIntroCont h2{font-size: 26px;}
    .HProTitles h2:before{font-size: 15px;}
    .HProTitles h2 {font-size: 60px;letter-spacing: -2px;}
    .HProTitles h2:after{font-size: 13px;}
    .HProTitles h6 {font-size: 8px;}
    .HProTitles h3 {font-size: 24px;letter-spacing: -1px;}
    .HProTitles h6{margin-bottom: 5px;}
    .HNICont h3 br{display: none;}
    .EnowInCont p br{display: none;}
    .TitleRightDiv {margin-top: 10px;}
    .HProTitles p{line-height: 1.3;}
    .HSVLists ul li strong {font-size: 26px;}
}