html{scroll-behavior: unset!important;}
body {margin: 0;font-family: 'Poppins', sans-serif;font-size: 16px;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
img{width: 100%;}
img:focus-visible{outline: none;}
a{text-decoration: none;}
ul{margin: 0;padding: 0;list-style: none;}
.aboutSec{height: 100vh;}
body.BHmExpended.false{overflow-y: auto;}
body.BHmExpended.true{overflow-y: hidden;}

.displaynone{display: none;}

section{position: relative;}
.textright{text-align: right;}
.textCenter{text-align: center;}
.titlemBtm{margin-bottom: 50px;}

.AuoSecBg img{width: 100%;height: 100%;display: block;}

.InnerPBanSec{position: relative;}
.IPBBgimg{position: relative;padding-top: 29.3%;overflow: hidden;}
.IPBBgimg img{position: absolute;width: 100%; height: 100%;top: 0;left: 0;right: 0;bottom: 0;object-fit: cover;}
.BanContSec{position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
.BanContSec .container{position: relative;height: 100%;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.BanContSec .textBlack{position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.BanContSec .textBlack h5{margin: 0;color: #ffffff;font-size: 31px;letter-spacing: -1px;text-transform: uppercase;}
.BanContSec .textBlack h1{margin: 0 15px;color: #ffffff;font-family: 'halyard_textblack';line-height: 0.8;font-size: 120px;letter-spacing: -2px;padding-bottom: 13px;text-transform: uppercase;}
.BanContSec .tbheadFive{position: absolute;top: 50%;-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);transform: translateY(-50%);}
.BanContSec .tbHeadLeft{left: 0;-webkit-transform: translate(-100% , -50%);-ms-transform: translate(-100% , -50%);transform: translate(-100% , -50%);}
.BanContSec .tbHeadRight{right:0;-webkit-transform: translate(100% , -50%);-ms-transform: translate(100% , -50%);transform: translate(100% , -50%);}

.SecBgimg{position: relative;}
.SecBgimg img{width: 100%;}
.ProContSec{/*position: absolute;top: 0;left: 0;width: 100%;height: 100%;*/padding: 100px 0;}
.KatbookPage .ProIntroSec{background: url(../images/katbookintroBg.jpg) no-repeat top left;background-size: 100%;}
.KatbookPage .ProContSec{background: url(../images/katbookintroBg.jpg) no-repeat bottom right;background-size: 100%;}
.KampusPage .ProIntroSec{background: url(../images/kampusintroBg.jpg) no-repeat top left;background-size: 100%;}
.KampusPage .ProContSec{background: url(../images/kampusintroBg.jpg) no-repeat bottom right;background-size: 100%;}
.KamaccPage .ProIntroSec{background: url(../images/kamaccintroBg.jpg) no-repeat top left;background-size: 100%;}
.KamaccPage .ProContSec{background: url(../images/kamaccintroBg.jpg) no-repeat bottom right;background-size: 100%;}

.tbTitle h2{font-family: 'halyard_textblack';text-transform: uppercase;font-size: 82px;line-height: 1;margin: 0;}
.tbTitle h2 small{font-size: 56%;text-transform: capitalize;}
.tbTitle h2 span{color: #3ab2cb;}
.tbTitle h3{font-family: 'halyard_textblack';text-transform: uppercase;font-size: 70px;line-height: 1;margin: 0;}
.tbTitle h3 span{color: #3ab2cb;}
.tbTitle h4{font-size: 56px;font-family: 'halyard_textblack';letter-spacing:-2px;line-height: 1;margin-bottom: 15px;}
.tbTitle p{margin: 0;}

.ktbkIntroC .tbTitle{margin-bottom: 100px;}
.ktbkList{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.ktbkList ul{width: auto;}
.ktbkList ul:first-child{margin-right: 80px;}
.ktbkList ul li{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 50px;}
.ktbkList ul li:last-child{margin-bottom: 0px;}
.ktbkList ul li strong{padding-left: 20px;font-size: 18px;line-height: 1.2;letter-spacing: -0.5px;color: #000000;font-weight: 600;}
.ktbkList ul li img{width:85px;background: #0e98b4;border-radius: 50%;box-shadow: 0 0 0 8px #d2eaf0;}
.ktbkYTDiv{position: absolute;bottom: 15%;right:14%;display: none;}
.ktbkYTDiv a{display: block;}
.ktbkYTDiv a{display: block;width: 85px;}

.kamaccIntroSec .tbTitle{text-align: right;}
.kamaccIntroSec .ktbkList{-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
.kamaccIntroSec .ktbkList ul:first-child{margin-right: 0;}
.kamaccIntroSec .ktbkList ul li strong{padding-left: 0;padding-right: 20px;text-align: right;}
.kamaccIntroSec .ktbkList ul li{-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;} 

.ProFeatuSec{}
.ProFeatuSec .container{position: relative;}
.ProFeatuSec::before{content: '';position: absolute;width: 100%;height: 375px;bottom: 0;left:0;background: #2b9fb7;}
.ProFeatuSec .tbTitle {padding:100px 0 50px;}
.textBlackrow{width: 100%;margin: 0 auto;}
.featboxitems{width: calc(100% - 40px);margin: 0 auto;}
.featboximg{position: relative;height: 440px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: end;-ms-flex-align: end;align-items: flex-end;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
.featboximg::before{content:"";position: absolute;width: 80%;height:75%;bottom: 0;right: 0;background: #2b9fb7;}
.featboximg img{width: auto;position: relative;margin-left: -12px;}
.fbitemOne .featboximg img{height: 100%;}
.fbitemTwo .featboximg img{height: 80%;}
.featboximg h3{margin: 0;position: absolute;top: 35%;right: 6%;font-family: 'halyard_textblack';font-size: 48px;line-height: 1;letter-spacing: -1px;color: #fff;text-transform: uppercase;}
.featboxpara{background: #fff;padding: 35px;min-height: 375px;width: 95%;margin: 0 auto;}
.featboxpara p{}
.featboxpara p:last-child{margin-bottom: 0;}

.ProAIPowSec{background: #2b9fb7;padding: 100px 0;overflow: hidden;}
.ProAIPowSec .tbTitle {margin-bottom: 50px;}
.ProAIPowSec .tbTitle h4{margin-bottom: 5px;color: #fff;}
.ProAIPowSec .tbTitle {width: 110%;}
.ProAIPowSec .tbTitle h2{color: #fff;font-size: 72px;}
.ProAIPowSec .tbTitle p{margin-bottom: 0px;color: #fff;}
.ProAIPowSec .AIPowSliders{margin: 50px 0 0 -15px;}
.ProAIPowSec .accordion{background: transparent;}
.ProAIPowSec .accordion-item{background: transparent;border-radius: 5px;overflow: hidden;border: 0;margin-top: -3px;}
.ProAIPowSec .accordion-item:first-of-type{margin-top: 0px;}
.ProAIPowSec h2.accordion-header{}
.ProAIPowSec button.accordion-button{background: rgb(27 96 109 / 50%);color: #fff;box-shadow: none;padding:0 20px;height: 60px;position: relative;border-bottom: 1px solid transparent;}
.ProAIPowSec button.accordion-button:before{content:'';opacity: 0.7;width:21px;height:21px;margin-right: 10px; background: url(../images/FourPointIcon.png) no-repeat center center;background-size: 100%;}
.ProAIPowSec button.accordion-button[aria-expanded="true"]:before{opacity: 1;}
.ProAIPowSec .accordion-item:first-of-type .accordion-button{border-radius: 0;}
.ProAIPowSec .accordion-button::after{background: transparent;content: '+';}
.ProAIPowSec .accordion-button:not(.collapsed)::after{background: transparent;transform: none;content: '-';}
.ProAIPowSec .accordion-collapse{background: #ffffff;}
.ProAIPowSec .accordion-item:last-of-type .accordion-collapse{border-radius: 0;}
.ProAIPowSec .accordion-body{min-height: 130px;}
.ProAIPowSec .accordion-body p.linkpara{margin: 10px 0 0;display: -webkit-box;display: -ms-flexbox;display: flex;}
.ProAIPowSec .accordion-body p.linkpara a{background: #3ab2cc;display: block;color: #fff;font-size: 90%;padding: 0 20px;line-height: 31px;border-radius: 20px;text-transform: uppercase;font-weight: 500;letter-spacing: 1px;border: 2px solid #3ab2cc;}
.ProAIPowSec .accordion-body p.linkpara a:hover{background: #ffffff;color: #3ab2cc;}
.ProAIPowSec button.accordion-button[aria-expanded="true"]{font-weight: 600;background: #fff;color:#000000;border-bottom: 1px solid #e2e3e3;}
.ProAIPowSec .ProAIPowSCol{position: relative;}
.ProAIPowSec .ProAIPowSCol .row{position: relative;z-index: 9;}
.ProAIPowSec .ProAIPowSCol .ProAIPowIMg img{width:100%;}
.ProAIPowSec .ProAIPowSCol .ProAIPowIMg{position: absolute;z-index: 0;}
.AIPowItem{background: #ffffff;margin: 10px;border-radius: 5px;padding: 12px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.AIPowItem img{width: 62px;}
.AIPowItem h4{margin: 0;padding-left: 15px;font-size: 20px;letter-spacing: -1px;font-weight: 600;}

.ProAIPowSec .katbokAipow .ProAIPowIMg{right: 4%;  top: -7%;width: 56%;-webkit-transform: translate(85%, 0%);-ms-transform: translate(85%, 0%);transform: translate(85%, 0%);}
.ProAIPowSec .kampusAipow .ProAIPowIMg{left: 0;bottom: -100px;width: 42%;-webkit-transform: translate(-72%, 0%);-ms-transform: translate(-72%, 0%);transform: translate(-72%, 0%);}
.ProAIPowSec .kampusAipow {margin-left: auto;}
.ProAIPowSec .kamaccAipow .ProAIPowIMg{right: 0;bottom: -100px;width: 42%;-webkit-transform: translate(80%, 0%);-ms-transform: translate(80%, 0%);transform: translate(80%, 0%);}

.ProRobSolSec{padding: 0;}
.ProRobSolSec .tbTitle h2{text-transform: unset;letter-spacing: -3px;margin-bottom: 10px;}
.RobSolItems{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.RobSolItem {padding: 0 5px;}
.RobSolItem img{width: 65px;background: #0f9bb7;border-radius: 50%;padding: 7px;}
.RobSolItem h4{font-size: 18px;color: #000000;letter-spacing: -1px;font-weight: 600;margin: 10px 0;}
.RobSolItem p{margin: 0;font-weight: 500;letter-spacing: -0.5px;line-height: 1.4;}
.RobSolSlideLink{position: absolute;bottom:10%;right: 7%;}
.linkbtn{position: relative;display: block;background: transparent;  border: none;}
.linkbtn strong{color: #2b3e60;font-size: 40px;text-transform: uppercase;font-family: 'halyard_textblack';letter-spacing: -1px;line-height: 0.8;display: block;}
.linkbtn img{position: absolute;width: 42px;top: 50%;right: 0;-webkit-transition: all 0.5s ease;-o-transition: all 0.5s ease;transition: all 0.5s ease;-webkit-transform: translate(110%, -50%);-ms-transform: translate(110%, -50%);transform: translate(110%, -50%);}
.linkbtn:hover img{-webkit-transform: translate(130%, -50%);-ms-transform: translate(130%, -50%);transform: translate(130%, -50%);}
.RobSolSlides{display: -webkit-box;display: -ms-flexbox;display: flex;overflow: hidden;}
.RobSolSlide{padding: 100px 0;-webkit-box-flex: 1;-ms-flex: 1 0 100%;flex: 1 0 100%;position: relative;overflow: hidden;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}
.RSSlideOne.RSSlideLOpen{margin-left: 0%;}
.RSSlideOne.RSSlideLHide{margin-left: -100%;}

.KatbookRobSolSec .RobSolItem{-webkit-box-flex: 0;-ms-flex: 0 0 33.333%;flex: 0 0 33.333%;margin-bottom: 25px;}
.KatbookRobSolSec .ProRobLcol img{width: 141%;margin-left: -41%;margin-top: 10%;}
.KatbookRobSolSec .ProRobLcol .exprbtn{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}

.KampusPage .ProRobSolSec .tbTitle h2{margin-bottom: 11px;}
.KampusRobSolSec .RobSolItem{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;margin-bottom: 25px;}
.KampusRobSolSec .ProRobBtmcol .RobSolItem{-webkit-box-flex: 1;-ms-flex: 1 0 33.3333%;flex: 1 0 33.3333%;margin-bottom: 0px;}
.KampusRobSolSec .ProRobLcol img{width: 100%;border-radius: 10px;box-shadow: 0 0 4px 1px rgb(51 51 51 / 22%);}
.KampusRobSolSec .ProRobLcol .exprbtn{-webkit-box-pack: end;-ms-flex-pack: end;justify-content: end;margin: 0;}
.KampusRobSolSec .ProRobLcol p.exprbtn a{margin: -20px 40px 0 0;}

.KamaccPage .ProRobSolSec .tbTitle h2{margin-bottom: 11px;}
.KamaccRobSolSec .RobSolItem{-webkit-box-flex: 1;-ms-flex:1 0 50%;flex: 1 0 50%;margin-bottom: 25px;}
.KamaccRobSolSec .ProRobLcol img{width: 100%;border-radius: 10px;box-shadow: 0 0 4px 1px rgb(51 51 51 / 22%);}
.KamaccRobSolSec .ProRobLcol .exprbtn{margin: 0;}
.KamaccRobSolSec .ProRobLcol p.exprbtn a{margin: -20px 0 0 40px;}
.KamaccRobSolSec .RobSolSlideLink{bottom: 6%;}

.ProApplinkSec{padding: 80px 0;background-color: #2fa6bd;display: none;}
.ProApplinkSec .container{position: relative;}
.ProApplinkSec .tbTitle h4{color: #ffffff;}
.ProApplinkSec .tbTitle h4 .colorchange{color: #000000;}
.ProApplinkSec .tbTitle ul{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-top: 30px;}
.ProApplinkSec .tbTitle ul li{margin-right: 10px;display: none;}
.ProApplinkSec .tbTitle ul li:nth-child(1){display: block;}
.ProApplinkSec .tbTitle ul li a {display: block;}
.ProApplinkSec .tbTitle ul li a img{width: auto;height: 55px;border-radius: 12px;}
.applinkdiv{position: absolute;bottom: -80px;right: 10%;width: 52%;}
.applinkdiv img{-webkit-filter: drop-shadow(2px 4px 9px #333);filter: drop-shadow(2px 4px 9px #333);}

.WChoKBSec{padding: 100px 0;background: url(../../asstes/images/WChooseKTBK-BGImg.jpg) no-repeat center center;background-size: 100% 100%;}
.WCHooTab{position: relative;}
.WCHooTab ul.nav-tabs{border: none;}
.WCHooTab ul.nav-tabs li.nav-item{margin: 0 20px;}
.WCHooTab ul.nav-tabs li.nav-item button{background: #f0e9d8;border: none;border-radius: 8px;padding: 10px 20px;}
.WCHooTab ul.nav-tabs li.nav-item button span{display: inline-block;position: relative;background: transparent!important;padding:10px 0 0 0; color:#848383;font-family: 'halyard_textblack';font-size: 55px;letter-spacing: -1px;}
.WCHooTab ul.nav-tabs li.nav-item button span::before{content:"For";position: absolute;font-family: 'Poppins', sans-serif;top: 0;left: 3px;text-transform: uppercase;font-size: 19px;font-weight: 300;}
.WCHooTab ul.nav-tabs li.nav-item button.active{background: #2b9fb7;}
.WCHooTab ul.nav-tabs li.nav-item button.active span{color: #fff;}
.WCHooTab ul.responsive-tabs{border: none;display: -webkit-box;display: -ms-flexbox;display: flex;}
.WCHooTab ul.responsive-tabs li{margin: 0 20px;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.WCHooTab ul.responsive-tabs li h4{background: #f0e9d8;border: none;border-radius: 8px;padding: 10px 20px;margin: 0;text-align: center;cursor: pointer;}
.WCHooTab ul.responsive-tabs li span{display: inline-block;position: relative;background: transparent!important;padding:10px 0 0 0; color:#848383;font-family: 'halyard_textblack';font-size: 55px;letter-spacing: -1px;}
.WCHooTab ul.responsive-tabs li span::before{content:"For";position: absolute;font-family: 'Poppins', sans-serif;top: 0;left: 3px;text-transform: uppercase;font-size: 19px;font-weight: 300;}
.WCHooTab ul.responsive-tabs li.active h4{background: #2b9fb7;}
.WCHooTab ul.responsive-tabs li.active h4 span{color: #fff;}
.WCHooTab .tab-content{position: relative;padding: 50px 20px;}
.WCHooTab .tab-content .row{-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.WCL-Cont h4{font-family: 'halyard_textblack';font-size: 46px;letter-spacing: -1px;margin: 0;line-height: 1;}
.WCL-Cont h5{font-family: 'halyard_textblack';font-size: 23px;letter-spacing: -1px;margin: 0 0 30px;}
.WCL-Cont p.exprbtn{margin-top: 50px;}
.WCL-Img{width: 90%;margin-left: auto; position: relative;}
.WCL-Img::before{content: ''; width:130%;position: absolute;bottom: -12.5%;left: -15%;padding-top: 130%;background: url(../../asstes/images/WCKB-StudbgImg.png) no-repeat center center;background-size: 100%;-webkit-animation: rotate-animation 60s infinite linear;animation: rotate-animation 60s infinite linear;}
.WCL-Img img{position: relative;}
@-webkit-keyframes rotate-animation {
	0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    50% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
	100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes rotate-animation {
	0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    50% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
	100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

.WChoKmpsSec .WCL-Cont{margin-left: 12%;}

.HoldonSec{padding: 100px 0;}
.HoldonSec .tbTitle h2{font-size: 140px;}
.HoldonSec .tbTitle p{font-size: 25px;text-transform: uppercase;} 
.honInROw{position: relative;}
.honInROw .row{margin: 0;border: 5px solid #c1e6ed;}
.honInROw .row .honCol{padding: 70px;}
.honCol h4{font-size: 34px;color: #000000;font-family: 'halyard_textblack';text-align: center;margin-bottom: 20px;}
.honCol h5{font-size: 18px;color: #000000;text-align: center;margin-bottom: 30px;}
.honColL {background: #e9f9fb;}
.HoCalen .G_eycHIIxsnntLHS95Nc._hAtIO8O1_pAj8K6LD93.mYAE8EC9vF9JJeVk837a {display: none;}
.HoldonSec form .btn {margin-top: 20px; position: relative;border: 1px solid transparent;background: #3ab2cc;color: #fff;display: block;line-height: 45px;border-radius: 30px;padding: 0 30px;font-size: 20px;  font-weight: 600;  box-shadow: 0 0 0 7px rgb(255 255 255 / 7%);  display: block;}
.HoldonSec form .btn:hover{background: #fff;color: #3ab2cc;border-color: #3ab2cc;}
.HoldonSec .ExpertForm .mb-3{margin-bottom: 20px!important;}
.HoldonSec .ExpertForm .form-control{padding: 0 20px;line-height: 41px;border-radius: 5px;}
.HoldonSec .field-error{background: red;color: #fff;padding: 0 10px;font-size: 13px;}
.HoldonSec .ExpertForm .form-control:focus{box-shadow: none;border-color: #3ab2cc;}
.HoCalen .react-calendar{margin: 0 auto 27px;width: 80%;font-family: 'Poppins', sans-serif;border: 8px solid #ecf6f9;border-radius: 7px;padding: 15px 30px;}
.HoCalen .react-calendar .react-calendar__month-view__days button{padding: 0;line-height: 50px;}
.HoCalen .react-calendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday{padding: 0;line-height: 50px;}
.honInROw .HoCalen{position: relative;height: 100%;}
.honInROw .HoCalen>div{position: relative;height: 100%;}
.honInROw .HoCalen>div>div{position: relative;height: 100%;}

.Tcontainer{overflow: hidden;transition: all 0.5s ease;max-height: 645px;}
.Tcontainer.open {max-height: 100%;}
.hidi {display: none;overflow: hidden;}
.alllessbtn{margin-top: 50px;display: flex;align-items: center;justify-content: center;position: relative;}
.alllessbtn:before{content:"";position: absolute;width: 100%;height:1px;left:0;top: 50%;background: #81c9d7;}
.alllessbtn button.more {position: relative; background: #ffffff;color: #565656;box-shadow: 0 0 0 10px #2b9fb7;font-weight: 700;border: 2px solid #fff;padding: 0px 30px;cursor: pointer;line-height: 40px;border-radius: 30px;}
.alllessbtn button.more:hover{background: #565656;color: #ffffff;}
.KamaccPage .alllessbtn{display: none;}

.modal-backdrop{z-index: 9999999;}
.modal{z-index: 99999999;}

.enqnowPopup {}
.enqnowPopup .modal-content{border: none;border-radius: 10px;box-shadow: 0 0 0 16px rgb(255 255 255 / 30%);}
.enqnowPopup .modal-header{padding: 0;position: absolute;top: 0;right: 0;z-index: 9;border: none;}
.enqnowPopup .modal-header .btn-close{display: block;background: #000;margin: 0;position: relative;border: 2px solid #fff;}
.enqnowPopup .modal-header .btn-close:before{content:'';position: absolute;width: 100%;height: 100%;left:0;top:0;background: url(../images/pop-close-icon.png) no-repeat center center;background-size: 74%;filter: brightness(0) invert(1);}
.enqnowPopup .modal-body{padding: 0;}
.enqnowPopup .modal-body .col-md-5 img{height: 100%;object-fit: cover;}
.popformcol{padding: 40px;}
.enqnowPopup .popformcol h3{font-family: 'halyard_textblack';  color: #1f96b0;text-transform: uppercase;margin-bottom: 40px;}
.enqnowPopup button.btn-primary{display: inline-block;position: relative;background: #3ab2cc;border-color: #3ab2cc;color: #fff;line-height: 45px;border-radius: 30px;padding: 0 30px;    font-size: 16px;text-transform: uppercase;letter-spacing: 1px;font-weight: 600;box-shadow: 0 0 0 7px rgb(255 255 255 / 7%);}
.enqnowPopup button.btn-primary:hover {background: #fff;color: #3ab2cc;border-color: #3ab2cc;}
.enqnowPopup .form-control{font-size: 14px;border-radius: 0;line-height: 45px;padding: 0 15px;}
.enqnowPopup .form-select{border-radius: 0;  padding: 0 15px;line-height: 43px;font-size: 14px;}
.enqnowPopup .form-select:focus{box-shadow: none;border-color: #3ab2cc;}
.enqnowPopup .form-label{display: block;margin: 0;font-size: 14px;position: absolute;background: #fff;top: -10px;left: 10px;padding: 0 5px;}
.enqnowPopup .posrel{position: relative;margin-bottom: 32px;}
.enqnowPopup  .field-error{    background: red;  color: #fff;  padding: 0 10px;  font-size: 13px;}

.HapClientSec, .HNewsSec{display: none;}

.OsoluIntroSec{padding: 100px 0;position: relative;background-color: #eff8fa;padding: 65px 0 140px;}
.OsoluIntroSec:before{content:"";position: absolute;mix-blend-mode: multiply;width:33%;height: 100%;top: 0;right: 10%;background: url(../images/OsoluIntroImg.jpg) no-repeat center top;background-size: 100%;}
.OsoluIntroSec .tbTitle{position: relative;}
.OsoluIntroSec .tbTitle h4{margin: 15px 0 20px;color: #000;}
.OsoluIntroSec .tbTitle p{margin-bottom: 15px;}
.OsoluIntroSec .tbTitle p:last-child{margin-bottom: 0px;}
.OsoluDetlSec{padding: 100px 0;}
.OsoluDetlSec .responsive-tabs-accordion{position: relative;}
.OsoluDetlSec .ContsLists::before{content: '';position: absolute;font-size: 5.5vw;line-height: 0.9;font-weight: 800;right: 0;bottom: 0;text-transform: uppercase;-webkit-transform: translate(14%, 115%);-ms-transform: translate(14%, 115%);transform: translate(14%, 115%);-webkit-text-stroke: 2px;}
.OsoluDetlSec .challengList::before{content: 'Challenges';}
.OsoluDetlSec .solutionList::before{content: 'Solutions';}
.OsoluDetlSec .tbTitle h2{text-transform: capitalize;}
.OsoluDetlSec .tbTitle h5{margin: 0px 0 -10px 5px;text-transform: uppercase;}
.OsoluDetlSec .responsive-tabs{display: -webkit-box;display: -ms-flexbox;display: flex;}
.OsoluDetlSec .responsive-tabs li {margin-right: 20px;}
.OsoluDetlSec .responsive-tabs li h4{position: relative;font-size: 44px;font-weight: 700;margin: 0;padding: 0 20px;line-height: 1.8;border-radius: 10px;cursor: pointer;}
.OsoluDetlSec .responsive-tabs li h4::before{content:"";position: absolute;border:8px solid transparent;border-top:8px solid  #2b9fb7;bottom: -15px;left:50%;margin-left: -4px;opacity: 0;}
.OsoluDetlSec .responsive-tabs li.active h4::before{opacity: 1;}
.OsoluDetlSec .responsive-tabs li.active h4{background: #2b9fb7;color: #fff;}
.OsoluDetlSec .tab-content{padding-top: 50px;}
.OsoluDetlSec .ContsLists ul{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.OsoluDetlSec .ContsLists ul li{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;padding: 0 25px 0 60px;position: relative;margin-bottom: 15px;}
.OsoluDetlSec .ContsLists ul li:before{content: '';position: absolute;width: 42px;height: 38px;top: 0;left:0;}
.OsoluDetlSec .ContsLists ul li h5{}
.OsoluDetlSec .ContsLists ul li p{margin: 0;}
.OsoluDetlSec .alllessbtn button.more{box-shadow: none;background: #2b9fb7;color: #fff;border-color: #2b9fb7;}
.OsoluDetlSec .alllessbtn button.more:hover{background: #ffffff;color: #2b9fb7;border-color: #ffffff;}

.OsoluStudntSec{background:#271d13 url(../images/OsStudySecBg.jpg) no-repeat bottom right;background-size:100%;}
.OsoluStudntSec .tbTitle h2{color: #ffffff;    text-transform: capitalize;}
.OsoluStudntSec .tbTitle h5{color: #ffffff; font-size: 26px;font-weight: 300;}
.OsoluStudntSec.OsoluDetlSec .responsive-tabs li h4{background: #41362c;color: #d5d5d5;font-family: 'halyard_textblack';}
.OsoluStudntSec.OsoluDetlSec .responsive-tabs li.active h4{background: #2b9fb7;color: #fff;}
.OsoluStudntSec.OsoluDetlSec .ContsLists::before{color:#271d13;-webkit-text-stroke-color: #524941;}
.OsoluStudntSec.OsoluDetlSec .ContsLists ul li:before{background: url(../images/FourPointIconOne.png) no-repeat center center;background-size: 100%;}
.OsoluStudntSec.OsoluDetlSec .ContsLists ul li h5{color: #f0f0ef;}
.OsoluStudntSec.OsoluDetlSec .ContsLists ul li p{color: #a69d8c;}
.OsoluStudntSec.OsoluDetlSec .alllessbtn:before{background: #524941;}

.OsoluParntSec{background:#ebf3fd  url(../images/OsParntSecBg.jpg) no-repeat bottom right;background-size:100%;}
.OsoluParntSec .row .col-md-8{margin-left: auto;}
.OsoluParntSec.OsoluDetlSec .responsive-tabs li h4{background: #ccd5de;color: #646567;font-family: 'halyard_textblack'}
.OsoluParntSec.OsoluDetlSec .responsive-tabs li.active h4{background: #2b9fb7;color: #fff;}
.OsoluParntSec.OsoluDetlSec .ContsLists::before{color:#ebf4fc;-webkit-text-stroke-color: #cfd6dd;}
.OsoluParntSec.OsoluDetlSec .ContsLists ul li:before{background: url(../images/FourPointIconTwo.png) no-repeat center center;background-size: 100%;}
.OsoluParntSec.OsoluDetlSec .ContsLists ul li h5{color: #040505;}
.OsoluParntSec.OsoluDetlSec .ContsLists ul li p{color: #3c3e40;}
.OsoluParntSec.OsoluDetlSec .alllessbtn:before{background: #d4dde4;}

.OsoluTeachSec{background:#000000  url(../images/OsTeachSecBg.jpg) no-repeat bottom right;background-size:100%;}
.OsoluTeachSec .tbTitle h2{color: #ffffff;}
.OsoluTeachSec .tbTitle h5{color: #ffffff;}
.OsoluTeachSec.OsoluDetlSec .responsive-tabs li h4{background: #322e2a;color: #837e72;font-family: 'halyard_textblack'}
.OsoluTeachSec.OsoluDetlSec .responsive-tabs li.active h4{background: #2b9fb7;color: #fff;}
.OsoluTeachSec.OsoluDetlSec .ContsLists::before{color:transparent;-webkit-text-stroke-color: #47423d;right: 35%;}
.OsoluTeachSec.OsoluDetlSec .ContsLists ul li:before{background: url(../images/FourPointIconThree.png) no-repeat center center;background-size: 100%;}
.OsoluTeachSec.OsoluDetlSec .ContsLists ul li h5{color: #ffffff;}
.OsoluTeachSec.OsoluDetlSec .ContsLists ul li p{color: #837e72;}
.OsoluTeachSec.OsoluDetlSec .alllessbtn:before{background: #606161;}

.OsoluInstituSec{background:#f2f2f2  url(../images/OsInstituSecBg.jpg) no-repeat bottom right;background-size:100%;}
.OsoluInstituSec .row .col-md-8{margin-left: auto;}
.OsoluInstituSec.OsoluDetlSec .responsive-tabs li h4{background: #cccccc;color: #5d5d5d;font-family: 'halyard_textblack'}
.OsoluInstituSec.OsoluDetlSec .responsive-tabs li.active h4{background: #2b9fb7;color: #fff;}
.OsoluInstituSec.OsoluDetlSec .ContsLists::before{color:transparent;-webkit-text-stroke-color: #e2e2e2;}
.OsoluInstituSec.OsoluDetlSec .ContsLists ul li:before{background: url(../images/FourPointIconTwo.png) no-repeat center center;background-size: 100%;}
.OsoluInstituSec.OsoluDetlSec .ContsLists ul li h5{color: #040505;}
.OsoluInstituSec.OsoluDetlSec .ContsLists ul li p{color: #3c3e40;}
.OsoluInstituSec.OsoluDetlSec .alllessbtn:before{background: #d4dde4;}


.OSAccesibSec{padding: 100px 0;background: #ecf9fc;overflow: hidden;}
.AccesiItems{position: relative;}
.AccesiItems:before{content: '';position: absolute;width: 25%;padding-top: 25%;top: 0;left: 0;background: url(../images/AccesiABImgOne.png) no-repeat center center;background-size: 100%;-webkit-transform: translate(-90%, 0px);-ms-transform: translate(-90%, 0px);transform: translate(-90%, 0px);}
.AccesiItems:after{content: '';position: absolute;width: 25%;padding-top: 25%;top: 0;right: 0;background: url(../images/AccesiABImgTwo.png) no-repeat center center;background-size: 100%;-webkit-transform: translate(90%, 0px);-ms-transform: translate(90%, 0px);transform: translate(90%, 0px);}
.AccesiItems .row{margin: 0;position: relative;z-index: 9;}
.AccesiItems .row .col-md-6{padding: 0;}
.AccesiItem {display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;height: 100%;flex-direction: column;box-shadow: 0 0 10px rgb(0 0 0 / 12%);border-radius: 15px;overflow: hidden;}
.AccesiItem.ColRevers {-webkit-box-orient: horizontal;-webkit-box-direction: reverse;-ms-flex-direction: row-reverse;flex-direction: row-reverse;}
.AccesiItem>div{-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;}
.AccesiImg{position: relative;overflow: hidden;}
.AccesiImg img{width: 100%;height:100%;     object-fit: cover;}
.AccesiImg h3{position: absolute;z-index: 9;bottom: 0;left: 0;width: 100%;margin: 0;padding: 10px 20px;color: #fff;font-size: 2vw;line-height: 1;font-weight: 700;}
/* .AccesiCont{position: relative; background: #fff;padding: 25px;line-height: 1.8;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;} */
.AccesiCont{position: relative; background: #fff;padding: 25px;line-height: 1.8;display: -webkit-box;display: -ms-flexbox;display: flex;}
/* .AccesiCont::before{content: '';position: absolute;border:10px solid transparent;top: 10%;} */
.ConLeft .AccesiCont::before{border-left:10px solid #2b9fb7;left:0;}
.ConRight .AccesiCont::before{border-right:10px solid #2b9fb7;right:0;}
.ConRight .AccesiCont{text-align: right;}
.AccesiCont p{margin: 0;}


.AccesiItem:hover img { transform: scale(1.1);transition-duration: 1s;}
.AccesiItem:hover { box-shadow: 0 0 21px rgb(0 0 0 / 38%);transition-duration: .5s;}

.OsoluIntroSec .tbTitle h2 {line-height: 58px;color: #000;font-size: 72px;}
.OSAccesibSec .tbTitle h2 span {font-size: 68px;letter-spacing: -2px;}
.OSAccesibSec .tbTitle h4 {color: #000;}

.ustudyPage .UstdyIntroC .ktbkList { margin-top: 19px; }
.ustudyPage .ProRobSolSec.ustudyRed .tbTitle h2 { margin-bottom: 20px; }
.ustudyPage .WChoKmpsSec .WCL-Cont {margin-left: 0%;}

.ustudyPage .ktbkList ul li img {padding: 15px; overflow: visible;}
.ustudyPage .RobSolItem img {overflow: visible; }
.ustudyPage .ProAIPowSec .tbTitle h4 { margin-bottom: 11px;}
.ustudyPage .WChoKmpsSec .WCL-Cont p{width: 90%;}
.OsoluTeachSec.OsoluDetlSec .ContsLists.challengList.teacher ul li p { color: #c1b79f; }

.PublishwusSec{position: relative;}
.PublishwusSec .container{position: relative;}
.PublishwusSec::before{content:"";position: absolute;width: 100%;;height:10%;left:0;bottom: 0;background: rgb(209,209,209);background: linear-gradient(0deg, rgba(209,209,209,1) 0%, rgba(209,209,209,0) 100%);opacity: 0.2;}
.PublishwusSec .outrow{margin: 0;}
.PublishwusSec .outcol{padding: 0;}
.pwusItems{padding-bottom: 80px;}
.pwsListCol{width: 100%;height: 100%;background: #eaf6f8;padding: 120px 20px 20px 40px;position: relative;}
.pwsListCol ul{position: sticky;top: 120px;margin-bottom: 100px;}
.pwsListCol ul li{margin:0 0 25px;padding-left: 28px;position: relative;}
.pwsListCol ul li:before{content:"";position: absolute;width: 110%;height:1px;left: -10%;bottom: -10px;background: #ffffff;}
.pwsListCol ul li a::before{content:'';position: absolute;width: 18px;height:18px;top:3px;left: 0;background: url(../images/listLIcon.png) no-repeat center center;background-size: 100%;-webkit-filter: brightness(0);filter: brightness(0);opacity: 0.4;}
.pwsListCol ul li a{color: #000000;text-transform: uppercase;line-height: 1.2;display: block;font-size: 18px;font-weight: 300;letter-spacing: -0.5px;cursor: pointer;}
.pwsListCol ul li a.active{font-weight: bold;}
.pwsListCol ul li a.active::before{-webkit-filter: brightness(1);filter: brightness(1);opacity: 1 ;}
.pwusConts{padding:100px 0 60px 50px;min-height: 100vh;}
.pwusConts .reverseRow {-webkit-box-orient: horizontal;-webkit-box-direction: reverse;-ms-flex-direction: row-reverse;flex-direction: row-reverse;}
.pwusConts .textBlack{margin-bottom: 40px;}
.pwusConts .textBlack h3{font-family: 'halyard_textblack';text-transform: uppercase;font-size: 50px;letter-spacing: -1px;line-height: 1; color:#000;margin-bottom: 15px;}
.pwusConts .textBlack h3 span{color:#3ab2cb;}
.pwusConts figure{margin: 0;}
.pwusConts .accordion-item{border-width: 1px 0;background: transparent;}
.pwusConts .accordion-item:first-of-type .accordion-button{border-top-left-radius:0;border-top-right-radius:0;}
.pwusConts .accordion-item:first-of-type {border-top-left-radius:0;border-top-right-radius:0;}
.pwusConts .accordion-item:last-of-type .accordion-collapse {border-bottom-right-radius: 0;border-bottom-left-radius:0;}
.pwusConts .accordion-item:last-of-type {border-bottom-right-radius: 0;border-bottom-left-radius:0;}
.pwusConts .accordion-button{color:#000000;letter-spacing: -0.5px;font-weight: 700;background: transparent;position: relative;}
.pwusConts .accordion-button:before{content:"";margin-right: 10px;width: 16px;height: 16px;background: url(../images/listLIcon.png) no-repeat center center;background-size: 100%;}
.pwusConts .accordion-button:focus{box-shadow:none;}
.pwusConts .accordion-button::after{content:"+";background: transparent;font-weight: 300;font-size: 24px;}
.pwusConts .accordion-button:not(.collapsed)::after{content:"-";transform:rotate(0);}
.pwusConts .accordion-button:not(.collapsed){background: transparent;}
.pwusConts .accordion-body p:last-child{margin-bottom: 0;}
.pwusConts .accordion ul{margin-bottom: 20px;list-style: disc;padding-left: 20px;}
.pwusConts .accordion ul li{}



p.stickybtn{position: absolute;}

p.stickybtn a {position: fixed;top:50%;right: -50px;transform: rotate(90deg);  background: #3ab2cc;
  color: #fff;
  line-height: 45px;
  border-radius: 30px;
  padding: 0 30px;
  font-size: 17px;
  letter-spacing: 2px;
  font-weight: 600;
  box-shadow: 0 0 0 7px rgb(255 255 255 / 7%);
  z-index: 99;
}
p.stickybtn a:hover { background: #fff;color: #3ab2cc;}

body.PblsusPage p.stickybtn{display: none;}


p.reg_btn{text-align: center;}
p.reg_btn a {display: inline-block;position: relative;background: #3ab2cc;border: 1px solid #3ab1cc00;color: #fff !important;line-height: 45px;border-radius: 30px;padding: 0 30px;font-size: 20px;font-weight: 600; box-shadow: 0 0 0 7px rgb(255 255 255 / 7%);}
p.reg_btn a:hover{background:#fff;color:#3ab2cc !important; border: 1px solid #3ab2cc;}

.ProInIntroSec, .ProInvideoSec, .ProInKeySec{padding: 100px 0;position: relative;}
.ProInIntroSec.kbook-unicov{background: url(../images/proinintrobg.jpg) no-repeat right center;background-size: auto 100%;}
.ProInIntroSec .tbTitle p{margin-bottom: 20px;}
.ProInIntroSec .tbTitle p:last-child{margin-bottom: 0px;}

.ProInKeySec{background: #eff8fa;}

.kbtabBox .responsive-tabs-accordion{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;}
.kbtabBox .responsive-tabs-accordion .navul{width: 30%;background: rgb(58 178 203 / 10%);padding: 30px ;position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kbtabBox .responsive-tabs-accordion .tab-content-container{width: 65%;padding: 5vh 1vw 5vh 5vw;background: #fff;}
.kbtabBox .responsive-tabs li {margin: 0 0 25px;padding-left: 28px;position: relative;}
.kbtabBox .responsive-tabs li:before {content: "";position: absolute;width: 106%;height: 1px;left: -3%;bottom: -10px;background: #ffffff;}
.kbtabBox .responsive-tabs li h5 {color: #000000;text-transform: uppercase;line-height:1.2;display: block;font-size: 110%;font-weight: 300;letter-spacing: -0.5px;cursor: pointer;margin: 0;padding: 5px 0;}
.kbtabBox .responsive-tabs li.active h5 {font-weight: bold;}
.kbtabBox .responsive-tabs li h5::before {content: '';position: absolute;width: 18px;height: 18px;top: 50%;-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);transform: translateY(-50%);left: 0;background: url(../images/listLIcon.png) no-repeat center center;background-size: 100%;filter: brightness(0);opacity: 0.4;}
.kbtabBox .responsive-tabs li:hover h5::before {filter: brightness(1);opacity: 1;}
.kbtabBox .responsive-tabs li.active h5::before {filter: brightness(1);opacity: 1;}
.kbtabBox .tab-content-container .row{-webkit-box-align: center;-ms-flex-align: center;align-items: center;}
.kbtabBox .KBCont h4{font-family: 'halyard_textblack';font-size: 2vw;letter-spacing: -1px;margin: 0 0 10px;line-height: 1;}  

.ProInvideoSec::before{content: '';position: absolute;width: 100%;height: 50%;left: 0;bottom: 0;background: #2fa6bd;pointer-events: none;}
.ProInvideoSec .container{position: relative;}
.ProInvideoSec .videobox{overflow: hidden;width: 90%;margin: 0 auto;border-radius: 10px;box-shadow: 0 0 0 10px rgb(58 178 204 / 13%);}
.ProInvideoSec .videobox .videoboxImg{position: relative;}
.ProInvideoSec .videobox .videoboxImg a{display: block;width: 100%;position: relative;overflow: hidden;}
.ProInvideoSec .videobox .videoboxImg a::before{content: '';position: absolute;width: 75px;height: 55px;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);background: url(../images/Youtube_logo.png) no-repeat center center;background-size: 100%;pointer-events: none;z-index: 9;}
.ProInvideoSec .videobox .videoboxImg img{display: block;width: 100%;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;-webkit-transform: scale(1);-ms-transform: scale(1);transform: scale(1);}
.ProInvideoSec .videobox:hover .videoboxImg img{-webkit-transform: scale(1.1);-ms-transform: scale(1.1);transform: scale(1.1);}
.ProInvideoSec .videobox .videoboxImg h3{font-family: 'halyard_textblack';text-transform: uppercase;font-size: 44px;line-height: 1;margin: 0;color: #ffffff;position: absolute;top: 40px;left: 40px;}
.ProInvideoSec .videobox .videoboxCOnt{padding: 30px;background: #ffffff;}
.ProInvideoSec .videobox .videoboxCOnt p{margin: 0;}


@media screen and (min-width:1200px) {
  .container{max-width: 75%;}
}
@media screen and (min-width:1300px) {
  .container{max-width: 70%;}
}
@media screen and (min-width:1721px) {
  .container{max-width: 1380px;}
}

@media screen and (max-width:1720px) {
  body{font-size: 15.5px;}
  .BanContSec .textBlack h1{font-size: 100px;}
  .BanContSec .textBlack h5{font-size: 30px;}
  .tbTitle h2{font-size: 74px;}
  .tbTitle h3{font-size: 64px;}
  .tbTitle h4 {font-size: 50px;}
  .ktbkIntroC .tbTitle {margin-bottom: 65px;}
  .ktbkList ul li strong{font-size: 17px;}
  .ktbkList ul li img {width: 80px;}
  .ktbkList ul li{margin-bottom: 36px;}
  .ktbkList ul:first-child {margin-right: 50px;}
  .featboximg h3{right: 4%;font-size: 38px;}
  .featboxpara{padding: 30px;}
  .featboxitems {width: calc(100% - 30px);}
  .KatbookPage .RobSolItems{width: 120%;}
  .KatbookRobSolSec .RobSolItem{min-height: 260px;margin-bottom: 15px;}
  .KatbookRobSolSec .ProRobLcol img{margin-left: -47%;}
  .WCHooTab ul.responsive-tabs li span{font-size: 47px;}
  .honInROw .row .honCol {padding: 45px 60px;}
  .honCol h5{margin-bottom: 18px;}
  .HoldonSec .tbTitle h2 {font-size: 115px;}
  .ProFeatuSec::before{height: 402px;}
  .featboxpara{min-height: 402px;}
  .titlemBtm{margin-bottom: 40px;}
  .OsoluDetlSec .responsive-tabs li h4{font-size: 40px;}
  .OsoluIntroSec .tbTitle h2 {line-height: 45px;font-size: 57px;}
  .pwusConts {padding: 100px 0 50px 40px;}
  .pwusConts .textBlack h3{font-size: 44px;}
  .pwsListCol{padding: 100px 15px 20px 25px;}
  .pwsListCol ul li a{font-size: 16px;}
  .ProInvideoSec .videobox .videoboxImg h3{font-size: 36px;}
}

@media screen and (max-width:1620px) {
  body{font-size: 15px;}
  .ProContSec {padding: 90px 0;}
  .ProFeatuSec .tbTitle {padding:90px 0 40px;}
  .ProAIPowSec {padding: 90px 0;}
  .ProAIPowSec .kampusAipow .ProAIPowIMg{bottom: -90px;}
  .ProAIPowSec .kamaccAipow .ProAIPowIMg{bottom: -90px;}
  .RobSolSlide{padding: 90px 0;}
  .ProApplinkSec {padding: 75px 0;}
  .WChoKBSec {padding: 90px 0;}
  .HoldonSec {padding: 90px 0;}
  .OsoluIntroSec {padding: 90px 0;}
  .OSAccesibSec {padding: 90px 0;}
  .OsoluDetlSec{padding: 90px 0;}
  .ProInIntroSec, .ProInvideoSec, .ProInKeySec{padding: 90px 0;}
  .BanContSec .textBlack h1 {font-size: 90px;}
  .featboxpara p {font-size: 14px;}
  .featboxpara {padding: 25px 30px;min-height: 340px;}
  .ProAIPowSec button.accordion-button{height: 55px;}
  .ProRobSolSec .tbTitle h2{    margin-bottom: 11px;}
  .titlemBtm {margin-bottom: 40px;}
  .tbTitle h2 {font-size: 68px;}  
  .tbTitle h3{font-size: 60px;}
  .tbTitle h4 {font-size: 44px;}
  .ProFeatuSec::before{height: 330px;}
  .ProAIPowSec .tbTitle h2 small{display: block;}
  .featboximg h3 {font-size: 34px;}
  .ProAIPowSec button.accordion-button:before{width: 16px;flex: 0 0 16px;height: 16px;}
  .RobSolItem img {width: 59px;}
  .RobSolItem h4{font-size: 16px;margin: 6px 0;}
  .KatbookRobSolSec .RobSolItem {min-height: 238px;margin-bottom: 12px;}
  .ProApplinkSec .tbTitle ul li a img{height: 50px;}
  .WCHooTab ul.responsive-tabs li span::before{font-size: 16px;}
  .WCHooTab ul.responsive-tabs li span {font-size: 42px;}
  .WCHooTab .tab-pane {padding: 40px 20px;}
  .HoldonSec .tbTitle h2 {font-size: 100px;}
  .WCL-Cont p br{display: none;}
  .HoldonSec .ExpertForm .form-control{line-height: 36px;font-size: 14px;}
  .HoldonSec .ExpertForm textarea.form-control{height: 80px;}
  .honCol h4{font-size: 30px;margin-bottom: 13px;}
  .HoCalen .react-calendar{width: 90%;}
  .honCol h5 {font-size: 16px;}
  .OsoluDetlSec .responsive-tabs li h4{font-size: 34px;}
  .OsoluDetlSec .ContsLists ul li h5 {font-size: 18px;}
  .OsoluDetlSec .tbTitle h5 {font-size: 18px;}
  .OsoluDetlSec .ContsLists ul li:before{width: 35px;height: 31px;}
  .OsoluDetlSec .ContsLists ul li{padding: 0 20px 0 50px;}
  .OsoluDetlSec .alllessbtn {margin-top: 40px;}
  .AccesiCont{line-height: 1.35;}
  .ProAIPowSec .tbTitle {margin-bottom: 40px;}
  .ProAIPowSec .tbTitle h2 {font-size: 60px;}
  .pwusConts .accordion-button{padding: 12px 15px;font-size: 15px;}
  .pwusConts .accordion-button:before{width: 15px;height: 15px;}
  .pwusConts .textBlack {margin-bottom: 35px;}
  .ProInvideoSec .videobox .videoboxImg h3{font-size: 32px;}

  p.stickybtn a {font-size: 15px;    line-height: 40px;  }

  
}
@media screen and (max-width:1500px){
  body{font-size: 14px;}
  .ProContSec {padding: 80px 0;}
  .ProFeatuSec .tbTitle {padding:80px 0 20px;}
  .ProAIPowSec {padding: 80px 0;}
  .ProAIPowSec .kampusAipow .ProAIPowIMg{bottom: -80px;}
  .ProAIPowSec .kamaccAipow .ProAIPowIMg{bottom: -80px;}
  .RobSolSlide{padding: 80px 0;}
  .ProApplinkSec {padding: 70px 0;}
  .WChoKBSec {padding: 80px 0;}
  .HoldonSec {padding: 80px 0;}
  .OsoluDetlSec{padding: 80px 0;}
  .OSAccesibSec {padding: 80px 0;}
  .OsoluIntroSec {padding: 80px 0;}
  .ProInIntroSec, .ProInvideoSec, .ProInKeySec{padding: 80px 0;}
  .BanContSec .textBlack h1 {font-size: 84px;}
  .BanContSec .textBlack h5 {font-size: 25px;}
  .tbTitle h2 {font-size: 58px;}
  .tbTitle h3{font-size: 52px;}
  .tbTitle h4 {font-size: 38px;}
  .ktbkList ul li strong {font-size: 15px;}
  .ktbkIntroC .tbTitle {margin-bottom: 55px;}
  .ktbkList ul li img {width: 70px;}
  .ktbkList ul:first-child {margin-right: 40px;}
  .ktbkYTDiv a {width: 65px;}
  .featboxitems {width: calc(100% - 0px);}
  .featboximg h3 {font-size: 32px;}
  .featboximg{height: 361px;}
  .featboxpara {padding: 25px 25px;min-height: 330px;}
  .featboxpara p {font-size: 13.5px;}
  .ProAIPowSec .tbTitle h2 {font-size: 60px;margin-bottom: 5px;}
  .ProAIPowSec button.accordion-button{font-size: 14px;}
  .ProAIPowSec button.accordion-button {height: 52px;}
  .ProAIPowSec .accordion-body {font-size: 13px;}
  .Tcontainer{max-height: 622px;}
  .RobSolItem img {width: 54px;padding: 6px;}
  .RobSolItem h4 {font-size: 15px;margin: 5px 0;}
  .KatbookRobSolSec .RobSolItem {min-height: 218px;}
  .ProApplinkSec .tbTitle h4{letter-spacing: 0px;}
  .WCHooTab ul.nav-tabs li.nav-item {margin: 0 13px;}
  .WCHooTab ul.responsive-tabs li span {font-size: 36px;}
  .WCL-Cont h4{font-size: 40px;}
  .WCL-Cont h5{font-size: 21px;margin:5px 0 15px;}
  .WCL-Cont h5 br{display: none;}
  .WCL-Cont p.exprbtn {margin-top: 35px;}
  .HoldonSec .tbTitle p{font-size: 20px;}
  .honCol h4 {font-size: 26px;}
  .honInROw .row .honCol {padding: 40px 50px;}
  .HoldonSec form .btn{line-height: 40px;padding: 0 25px;font-size: 17px;}
  .HoCalen .react-calendar{margin: 0 auto 20px;width: 100%;}
  .HoCalen .react-calendar .react-calendar__month-view__days button {line-height: 35px;}
  .RobSolSlideLink{bottom: 5%;right: 5%;}
  .linkbtn strong{font-size: 35px;}
  .linkbtn img{width: 32px;}
  .OsoluDetlSec .responsive-tabs li h4 {font-size: 32px;}
  .OsoluDetlSec .tab-content {padding-top: 40px;}
  .OsoluDetlSec .ContsLists ul li h5 {font-size: 17px;}
  .OsoluDetlSec .alllessbtn {margin-top: 30px;}
  .OsoluDetlSec .tbTitle h5{font-size: 20px;}
  .AccesiCont{padding: 20px;}
  .AccesiItems:before{-webkit-transform: translate(-95%, 0px);-ms-transform: translate(-95%, 0px);transform: translate(-95%, 0px);}
  .AccesiItems:after{-webkit-transform: translate(95%, 0px);-ms-transform: translate(95%, 0px);transform: translate(95%, 0px);}
  .pwusConts .textBlack {margin-bottom: 35px;}
  .pwsListCol ul{top: 90px;}
  .pwsListCol ul li a {font-size: 15px;}
  .pwsListCol {padding: 80px 15px 20px 20px; }
  .pwsListCol ul li{padding-left: 22px;}
  .pwsListCol ul li a::before{width: 14px;height: 14px;}
  .pwusConts {padding: 80px 0 50px 40px;}
  .pwusConts .accordion-body{padding: 10px 15px;}
  .pwusConts .accordion-button::after{font-size: 20px;width: 16px;height: 16px;line-height: 16px;}
  .pwusConts .textBlack h3 {font-size: 40px;}
  .ProInvideoSec .videobox .videoboxImg h3 {font-size: 28px;}

}
@media screen and (max-width:1400px){
  body{font-size: 13.5px;}
  .ProContSec {padding: 75px 0;}
  .ProFeatuSec .tbTitle {padding:75px 0 15px ;}
  .ProAIPowSec {padding: 75px 0;}
  .ProAIPowSec .kampusAipow .ProAIPowIMg{bottom: -75px;}
  .ProAIPowSec .kamaccAipow .ProAIPowIMg{bottom: -75px;}
  .RobSolSlide{padding: 75px 0;}
  .ProApplinkSec {padding: 65px 0;}
  .WChoKBSec {padding: 75px 0;}
  .HoldonSec {padding: 75px 0;}
  .OsoluDetlSec{padding: 75px 0;}
  .OSAccesibSec {padding: 75px 0;}
  .OsoluIntroSec {padding: 75px 0;}
  .ProInIntroSec, .ProInvideoSec, .ProInKeySec{padding: 75px 0;}
  .BanContSec .textBlack h1 {font-size: 75px;}
  .BanContSec .textBlack h5 {font-size: 22px;}
  .tbTitle h2 {font-size: 52px;}
  .tbTitle h3{font-size: 46px;}
  .tbTitle h4 {font-size: 36px;letter-spacing: -1px;}
  .ktbkIntroC .tbTitle {margin-bottom: 46px;}
  .ktbkList ul li strong {font-size: 14px;}
  .ktbkList ul li img {width: 62px;box-shadow: 0 0 0 6px #d2eaf0;}
  .featboxpara p {font-size: 13px;}
  .ProAIPowSec button.accordion-button {height: 46px;font-size: 13.5px;}
  .Tcontainer {max-height: 562px;}
  .alllessbtn button.more{padding: 0px 25px;line-height: 35px;}
  .RobSolItem h4 {font-size: 14px;}
  .RobSolItem p{font-size: 12.5px;}
  .KatbookRobSolSec .RobSolItem {min-height: auto;padding: 0 5px;}
  .ProApplinkSec .tbTitle ul li a img {height: 45px;}
  .HoldonSec .tbTitle h2 {font-size: 85px;}
  .HoldonSec .tbTitle p {font-size: 16px;}
  .honInROw .row .honCol {padding: 30px 50px;}
  .HoldonSec .ExpertForm textarea.form-control {height: 80px;}
  .HoldonSec .ExpertForm .form-control {line-height: 32px;font-size: 13.5px;}
  .WCL-Cont h4 {font-size: 34px;}
  .featboxpara p {margin-bottom: 10px;}
  .WCHooTab ul.responsive-tabs li span {font-size: 32px;}
  .ProFeatuSec::before {height: 333px;}
  .OsoluDetlSec .responsive-tabs li h4 {font-size: 26px;}
  .OsoluDetlSec .ContsLists ul li h5 {font-size: 15px;margin-bottom: 5px;}
  .OsoluDetlSec .ContsLists ul li p{line-height: 1.4;}
  .OsoluDetlSec .tbTitle h5 {font-size: 15px;}
  .OsoluDetlSec .titlemBtm {margin-bottom: 30px;}
  .OsoluDetlSec .ContsLists ul li {padding: 0 15px 0 42px;margin-bottom: 12px;}
  .OsoluDetlSec .ContsLists ul li:before {width: 31px;height: 28px;}
  .OsoluDetlSec .alllessbtn {margin-top: 20px;}
  .AccesiCont {padding: 18px;}
  .AccesiCont p {font-size: 95%;}
  .pwusConts .accordion-button {padding: 10px 12px;font-size: 14px;}
  .pwsListCol ul li {padding-left: 20px;}
  .pwsListCol ul li a {font-size: 14px;}
  p.stickybtn a {font-size: 14px;line-height: 36px;padding: 0 23px;}
  .ProInvideoSec .videobox .videoboxImg h3 {font-size: 25px;top: 30px;left: 30px;}
}

@media screen and (max-width:1200px){
  .AccesiItems:before {transform: translate(-70%, 0px); }
  .AccesiItems:after {transform: translate(70%, 0px); }
  .BanContSec .textBlack h1 {font-size: 62px;}
  .BanContSec .textBlack h5 {font-size: 18px;}
}

@media screen and (max-width:1100px){
  .ProContSec {padding: 75px 0;}
  .ProFeatuSec .tbTitle {padding:75px 0 20px;}
  .ProAIPowSec {padding: 75px 0;}
  .ProAIPowSec .kampusAipow .ProAIPowIMg{bottom: -75px;}
  .ProAIPowSec .kamaccAipow .ProAIPowIMg{bottom: -75px;}
  .RobSolSlide{padding: 75px 0;}
  .ProApplinkSec {padding: 65px 0;}
  .WChoKBSec {padding: 75px 0;}
  .HoldonSec {padding: 75px 0;}
  .OsoluDetlSec{padding: 75px 0;}
  .OSAccesibSec {padding: 75px 0;}
  .OsoluIntroSec {padding: 75px 0;}
  .ProInIntroSec, .ProInvideoSec, .ProInKeySec{padding: 75px 0;}
  .OsoluDetlSec .ContsLists::before{-webkit-transform: translate(0%, 115%);-ms-transform: translate(0%, 115%);transform: translate(0%, 115%);}
  .OsoluDetlSec .responsive-tabs li h4 {font-size: 22px;}
  .AccesiItems:after {-webkit-transform: translate(60%, 0px);-ms-transform: translate(60%, 0px);transform: translate(60%, 0px);}
  .AccesiItems:before {-webkit-transform: translate(-60%, 0px);-ms-transform: translate(-60%, 0px);transform: translate(-60%, 0px);}
  .PublishwusSec .col-md-3.outcol{display: none;}
  .PublishwusSec .col-md-9{width: 100%;}
  .pwusConts {padding: 80px 40px 40px 40px;min-height: auto;}
  .pwusConts .textBlack h3 {font-size: 36px;  }

}
@media screen and (max-width:1100px){
    body {font-size: 13px;}
    .ProContSec {padding: 70px 0;}
    .ProFeatuSec .tbTitle {padding:70px 0 20px;}
    .ProAIPowSec {padding: 70px 0;}
    .ProAIPowSec .kampusAipow .ProAIPowIMg{bottom: -70px;}
    .ProAIPowSec .kamaccAipow .ProAIPowIMg{bottom: -70px;}
    .RobSolSlide{padding: 70px 0;}
    .ProApplinkSec {padding: 60px 0;}
    .WChoKBSec {padding: 70px 0;}
    .HoldonSec {padding: 70px 0;}
    .OsoluDetlSec{padding: 70px 0;}
    .OSAccesibSec {padding: 70px 0;}
    .OsoluIntroSec {padding: 70px 0;}
    .ProInIntroSec, .ProInvideoSec, .ProInKeySec{padding: 70px 0;}
    .BanContSec .textBlack h1 {font-size: 64px;}
    .BanContSec .textBlack h5 {font-size: 19px;letter-spacing: -0.5px;}
    section {overflow: hidden;}
    .KatbookPage .ProIntroSec,.KampusPage .ProIntroSec,.KamaccPage .ProIntroSec{background: transparent;}
    .KatbookPage .ProContSec{background: url(../images/katbookintroTabBg.jpg) no-repeat bottom right;background-size: 100%;}
    .KampusPage .ProContSec{background: url(../images/kampusintroTabBg.jpg) no-repeat bottom right;background-size: 100%;}
    .KamaccPage .ProContSec{background: url(../images/kamaccintroTabBg.jpg) no-repeat bottom right;background-size: 100%;}
    .ktbkYTDiv {bottom: 10%;}
    .tbTitle h2 {font-size: 46px;}
    .tbTitle h3{font-size: 38px;}
    .tbTitle h4 {font-size: 32px;letter-spacing: -0.5px;}
    .ProAIPowSec .tbTitle {width: 100%;  }
    .ProFeatuSec::before {height: 345px;bottom: -5px;  }
    .KatbookPage .RobSolItems {width: 100%;}
    .KatbookRobSolSec .ProRobLcol img{width: 112%;margin-left: -10%;}
    .ProAIPowSec .kamaccAipow .ProAIPowIMg{-webkit-transform: translate(70%, 0%);-ms-transform: translate(70%, 0%);transform: translate(70%, 0%);}
    .RobSolSlideLink {bottom: 3%;right: 5%;  }
    .linkbtn strong {font-size: 30px;}
    .linkbtn img {width: 24px;}
    .WCL-Cont h5{font-size: 20px;letter-spacing: 0;}      
    .HoldonSec .tbTitle h2 {font-size: 78px;  }                                                                              
    .titlemBtm {margin-bottom: 20px;}
    .ProInvideoSec .videobox .videoboxImg h3 {font-size: 22px;}


}

@media screen and (max-width:1000px) {
  .AccesiItem { flex-wrap: inherit; }
}

@media screen and (max-width:800px){  
  body {font-size: 13.5px;}
  .ProContSec {padding:60px 0 70%;}
  .ProFeatuSec .tbTitle {padding:60px 0 20px;}
  .ProAIPowSec {padding: 60px 0;}
  .ProAIPowSec .kampusAipow .ProAIPowIMg{bottom: -60px;}
  .ProAIPowSec .kamaccAipow .ProAIPowIMg{bottom: -60px;}
  .RobSolSlide{padding: 60px 0 80px;}
  .ProApplinkSec {padding: 50px 0;}
  .WChoKBSec {padding: 60px 0;}
  .HoldonSec {padding: 60px 0;}
  .OsoluDetlSec{padding: 60px 0;}
  .OSAccesibSec {padding: 60px 0;}
  .OsoluIntroSec {padding: 60px 0;}
  .ProInIntroSec, .ProInvideoSec, .ProInKeySec{padding: 60px 0;}
  .tbTitle p br{display: none;}
  .BanContSec .textBlack h1 {font-size: 42px;}
  .BanContSec .textBlack h5 {font-size: 15px;letter-spacing: 0;}
  .tbTitle h2 {font-size: 42px;}
  .tbTitle h3{font-size: 34px;}
  .tbTitle h4 {font-size: 28px;}
  .tbTitle h4 br{display: none;}
  .KatbookPage .ProIntroSec{background: url(../images/katbookintroMobBgOne.jpg) no-repeat bottom right;background-size: 100% 100%;}
  .KampusPage .ProIntroSec{background: url(../images/kampusintroMobBgOne.jpg) no-repeat bottom right;background-size: 100% 100%;}
  .KamaccPage .ProIntroSec{background: url(../images/kamaccintroMobBgOne.jpg) no-repeat bottom right;background-size: 100% 100%;}
  .KatbookPage .ProContSec{background: url(../images/katbookintroMobBg.jpg) no-repeat bottom right;background-size: 100%;}
  .KampusPage .ProContSec{background: url(../images/kampusintroMobBg.jpg) no-repeat bottom right;background-size: 100%;}
  .KamaccPage .ProContSec{background: url(../images/kamaccintroMobBg.jpg) no-repeat bottom right;background-size: 100%;}
  .ktbkList ul {width: 50%;}
  .ktbkList ul:first-child {margin-right: 0;}
  .ktbkList ul li strong{width:100%;padding:0 20px;}
  .ktbkList ul li strong br{display: none;}
  .ktbkYTDiv {bottom: 8%;right: 45%;}
  .featboximg {height: 245px;}
  .featboximg h3 {font-size: 25px;}
  .featboxpara {padding: 25px 25px;min-height: 450px;}
  .ProFeatuSec::before {height: 455px;bottom: -5px;}
  .ProAIPowSec .tbTitle h2 {font-size: 50px;margin-bottom: 5px;}
  .ProAIPowSec .ProAIPowSCol .ProAIPowIMg{display: none;}
  .ProAIPowSec .ProAIPowSCol {width: 100%;}
  .ProRobSolSec .tbTitle h2 {letter-spacing: -1px;}
  .ProRobLcol {width: 100%;}
  .ProRobRcol {width: 100%;}
  .KatbookRobSolSec .RobSolSlide .col-md-12{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
  .KatbookRobSolSec .RobSolSlide .ProRobLcol{-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
  .KatbookRobSolSec .RobSolSlide .ProRobRcol{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
  .KampusRobSolSec .RobSolSlide .col-md-12{-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
  .KampusRobSolSec .RobSolSlide .ProRobLcol{-webkit-box-ordinal-group: 5;-ms-flex-order:4;order: 4;}
  .KampusRobSolSec .RobSolSlide .ProRobRcol{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
  .KampusRobSolSec .RobSolSlide .ProRobBtmcol {-webkit-box-ordinal-group: 4;-ms-flex-order: 3;order: 3;}
  .KampusRobSolSec .RobSolItem {flex: 0 0 50%;margin-bottom: 20px;}
  .KampusRobSolSec .ProRobBtmcol .RobSolItem {flex: 1 0 50%;margin-bottom: 20px;}
  .RobSolItem p br{display: none;}
  .kamaccAipow  .Tcontainer{max-height: 100%;}

  .applinkdiv{right: 0%;width: 61%;bottom: -52px;}
  .WCHooTab ul.responsive-tabs li {margin: 0 10px;}
  .WCHooTab ul.responsive-tabs li h4{padding: 6px 15px;}
  .WCHooTab ul.responsive-tabs li span {font-size: 26px;}
  .HoldonSec .tbTitle h2 {font-size: 64px;}
  .honInROw .row .honCol {padding: 20px 25px;}
  .AccesiItems .row .col-md-4 {width: 100%;}

  .AccesiItems .row .col-md-4 { margin-bottom: 25px;}
  .SolutionsPage .AccesiItem {justify-content: flex-start; flex-direction: unset;}
  .SolutionsPage .AccesiItem>div {flex: 0 0 100%;}
  .SolutionsPage .AccesiImg h3 { font-size: 5vw; }
  .SolutionsPage .AccesiCont p {font-size: 15px;}
  .pwusItems {padding-bottom: 0;}
  .pwusConts{padding: 60px 0;border-bottom: 1px solid #f0f0f0;}
  .pwusConts.ListContSeven{border-bottom: 0px solid #f0f0f0;}
  .pwusConts .textBlack h3 {font-size: 32px;}


  p.stickybtn a{display: none;}
}

@media screen and (max-width:550px){
  body {font-size: 14px;}    
  .ProContSec {padding:40px 0 70%;}
  .contactFormSec .ProContSec {padding:0%;}
  .ProFeatuSec .tbTitle {padding:40px 0 20px;}
  .ProAIPowSec {padding: 40px 0;}
  .ProAIPowSec .kampusAipow .ProAIPowIMg{bottom: -40px;}
  .ProAIPowSec .kamaccAipow .ProAIPowIMg{bottom: -40px;}
  .RobSolSlide{padding: 40px 0;}
  .ProApplinkSec {padding:30px 0 65%;}
  .WChoKBSec {padding: 40px 0;}
  .HoldonSec {padding: 40px 0;}
  .OsoluDetlSec{padding: 40px 0 100%;}
  .OSAccesibSec {padding: 40px 0;}
  .OsoluIntroSec {padding: 40px 0 100%;}
  .ProInKeySec{padding: 40px 0;}
  .ProInIntroSec{padding: 40px 0 40vh;}
  .ProInIntroSec.kbook-unicov {background: url(../images/proinintrobg.jpg) no-repeat right bottom;background-size: 200%;}
  .ProInvideoSec{padding: 40px 0;}
  .IPBBgimg{padding-top:60.3%;}
  .BanContSec .tbheadFive{position: relative;top: 0;}     
  .BanContSec .textBlack{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}                        
  .BanContSec .tbHeadLeft {left: 0;-webkit-transform: translate(0% , 0%);-ms-transform: translate(0% , 0%);transform: translate(0% , 0%);}
  .BanContSec .tbHeadRight {right: 0;-webkit-transform: translate(0% , 0%);-ms-transform: translate(0% , 0%);transform: translate(0% , 0%);}  
  .BanContSec .textBlack h5 {font-size: 14px;text-align: center;}
  .BanContSec .textBlack h1 {font-size: 42px;padding-bottom: 10px;margin-top: 5px;}
  .BanContSec .container{-webkit-box-align: end;-ms-flex-align: end;align-items: flex-end;padding-bottom: 45px;}
  .tbTitle h2 {font-size: 32px;}
  .tbTitle h3{font-size: 28px;margin-bottom: 10px;}
  .tbTitle h4 {font-size: 20px;line-height: 1.2;margin-bottom: 8px;}
  .ProInvideoSec .videobox{width: 100%;margin: 15px auto;}
  .ProInvideoSec .videobox .videoboxImg a::before{width: 52px;height: 38px;}

  .ProIntroSec .tbTitle p{text-align: justify;}
  .ktbkIntroC .tbTitle {margin-bottom: 30px;}
  .ktbkList ul {width: 100%;}
  .ktbkList ul li,.ktbkList ul:first-child li:last-child {margin-bottom: 17px;}
  .ktbkYTDiv a {width: 38px;}
  .ktbkYTDiv {bottom: 5%;right: 48%;}

  .featboxpara {padding: 25px 25px;min-height: auto;width: 100%;}
  .featboxitems.fbitemTwo{margin-top: -40px;}
  .ProFeatuSec::before {opacity: 0;visibility: hidden;pointer-events: none;}
  .featboximg::before{width: 87%;margin-right: -20px;}
  .featboximg img{margin-left: -22px;}
  .ProAIPowSec .tbTitle {margin-bottom: 30px;}
  .ProAIPowSec .tbTitle h2 {font-size: 36px;margin-bottom: 5px;}
  .KatbookRobSolSec .RobSolItem {-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;}
  .RobSolSlideLink{display: none;}
  .RobSolSlides{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
  .RSSlideOne.RSSlideLHide {margin-left: 0;}
  .ProApplinkSec .container {position: unset;}
  .RobSolSlide.RSSlideOne {position: relative;}
  .RobSolSlide.RSSlideOne:before{content:"";position: absolute;width: 80%;height:1px;left: 10%;bottom: 0;background: #f0f0f0;}
  .applinkdiv {right: 0%;width: 100%;bottom: -1px;}
  .ProApplinkSec .tbTitle h4{text-align: center;}
  .ProApplinkSec .tbTitle h4 br{display: none;}
  .ProApplinkSec .tbTitle ul{margin-top: 17px;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
  .accordion-tab-title{}
  .accordion-tab-content {padding: 10px;display: none;background: #fff; border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;}
  .accordion-tab.active .accordion-tab-content {display: block;}
  .WCHooTab .accordion-tab {margin-bottom: 15px;}
  .WCHooTab .accordion-tab-title h4{background: #f0e9d8;border: none;border-radius: 8px;padding: 10px 20px;margin: 0;text-align: center;cursor: pointer;}
  .WCHooTab .accordion-tab-title h4 span{display: inline-block;position: relative;background: transparent!important;padding:10px 0 0 0; color:#848383;font-family: 'halyard_textblack';font-size:30px;letter-spacing: -1px;}
  .WCHooTab .accordion-tab-title h4 span::before{content:"For";position: absolute;font-family: 'Poppins', sans-serif;top: 0;left: 3px;text-transform: uppercase;font-size: 13px;font-weight: 300;}
  .WCHooTab .active .accordion-tab-title h4{background: #2b9fb7;border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
  .WCHooTab .active .accordion-tab-title h4 span{color: #fff;}
  .WCHooTab .accordion-tab-content .col{width: 100%;-webkit-box-flex: 1;-ms-flex: 1 0 100%;flex: 1 0 100%;}
  .WCL-Img {width:70%;margin-left: auto;position: relative;margin-right: auto;margin-bottom: 40px;}
  .WChoKmpsSec .WCL-Cont {margin-left: 0;}
  .WCL-Cont p.exprbtn {margin-top: 14px;}
  .HoldonSec .tbTitle h2 {font-size: 54px;}
  .HoldonSec form .btn{margin: 0 auto;}
  .honCol h4 {font-size: 21px;}
  .honCol h5 {font-size: 14px;}
  .kamaccIntroSec .tbTitle {text-align: left;}
  .kamaccIntroSec .ktbkList ul li {-webkit-box-orient: horizontal;-webkit-box-direction: reverse;-ms-flex-direction: row-reverse;flex-direction: row-reverse;}
  .kamaccIntroSec .ktbkList ul li strong {padding-left: 20px;padding-right: 0;text-align: left;}
  .enqnowPopup .modal-body .col-md-5 {display: none;}
  .honInROw .HoCalen>div>div {position: relative;height: 650px;}
  .OsoluStudntSec {background: #271d13 url('../images/OsStudySecBg.jpg') no-repeat bottom right;background-size: 200%;}
  .OsoluParntSec {background: #ebf3fd url('../images/OsParntSecBg.jpg') no-repeat bottom left;background-size: 220%;}
  .OsoluTeachSec {background: #000000 url('../images/OsTeachSecBg.jpg') no-repeat bottom right;background-size: 200%;}
  .OsoluInstituSec {background: #f2f2f2 url('../images/OsInstituSecBg.jpg') no-repeat bottom left;background-size: 200%;}
  .OsoluIntroSec:before {mix-blend-mode: unset;width: 100%;height: 38%;top: auto;right: 0;bottom: 0;background: #ffffff url('../images/OsoluIntroImg.jpg') no-repeat 30% top;background-size: 74%;}
  .OsoluDetlSec .tbTitle h5 {margin: 0px 0 0px 0px;}
  .OsoluDetlSec .accordion-tab{margin-bottom: 10px;}
  .OsoluDetlSec .accordion-tab-title h4{position: relative;font-size: 24px;font-weight: 700;margin: 0;padding: 0 20px;line-height: 1.8;border-radius: 10px;cursor: pointer;}
  .OsoluDetlSec .active .accordion-tab-title h4{border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
  .OsoluDetlSec .accordion-tab-title h4::before{content: '';position: absolute;border-left: 6px solid transparent;border-right: 6px solid transparent;border-top: 12px solid #fff;top: 50%;right: 20px;margin-top: -6px;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;-webkit-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);}
  .OsoluDetlSec .active .accordion-tab-title h4::before{-webkit-transform: rotate(180deg);-ms-transform: rotate(180deg);transform: rotate(180deg);}
  .OsoluParntSec.OsoluDetlSec .accordion-tab-title h4::before{border-top-color: #5d5d5d;}
  .OsoluParntSec.OsoluDetlSec .active .accordion-tab-title h4::before{border-top-color: #ffffff;}
  .OsoluInstituSec.OsoluDetlSec .accordion-tab-title h4::before{border-top-color: #5d5d5d;}
  .OsoluInstituSec.OsoluDetlSec .active .accordion-tab-title h4::before{border-top-color: #ffffff;}
  .OsoluStudntSec .accordion-tab-title h4{background: #41362c;color: #d5d5d5;}
  .OsoluStudntSec .active .accordion-tab-title h4{background: #2b9fb7;color: #fff;}
  .OsoluParntSec .accordion-tab-title h4{background: #ccd5de;color: #646567;}
  .OsoluParntSec .active .accordion-tab-title h4{background: #2b9fb7;color: #fff;}
  .OsoluTeachSec .accordion-tab-title h4{background: #322e2a;color: #837e72;}
  .OsoluTeachSec .active .accordion-tab-title h4{background: #2b9fb7;color: #fff;}
  .OsoluInstituSec .accordion-tab-title h4{background: #cccccc;color: #5d5d5d;}
  .OsoluInstituSec .active .accordion-tab-title h4{background: #2b9fb7;color: #fff;}
  .OsoluDetlSec .accordion-tab-content{background: transparent;padding: 24px 10px;}
  .OsoluDetlSec .ContsLists ul li {-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;}
  .OsoluDetlSec .ContsLists ul li:last-child{margin: 0;}
  .OsoluDetlSec .ContsLists::before{opacity: 0;}
  .AccesiItem{-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;flex-direction: unset;  }
  .AccesiItem>div {-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;}
  .AccesiCont p {font-size: 100%;line-height: 1.6;}
  .ConRight .AccesiCont {text-align: left;}
  .AccesiItem.ColRevers {-webkit-box-orient: unset;-webkit-box-direction: unset;-ms-flex-direction: unset;flex-direction: unset;}
  .AccesiItems .row .col-md-6{margin-bottom: 25px;}
  .AccesiItems .row .col-md-6:last-child{margin-bottom: 0px;}
  .AccesiCont::before{opacity: 0;visibility: hidden;}
  .AccesiCont::after {content: '';position: absolute;border-left: 8px solid transparent;border-right: 8px solid transparent;border-top:12px solid #2b9fb7;top: 0%;right:20%}
  .AccesiImg h3{font-size: 8vw;}
  .OsoluIntroSec .tbTitle h2 { line-height: 34px; font-size: 42px; }
  .OSAccesibSec .tbTitle h2 span { font-size: 45px; }
  .ustudyPage .WChoKmpsSec .WCL-Cont p{width: 100%;}
  .pwusConts .textBlack h3 {font-size: 26px;}
  .pwusConts .textBlack h3 br{display: none;}
  .pwusConts {padding: 40px 10px;}
  .pwusConts .row{-webkit-box-orient: vertical;-webkit-box-direction: reverse;-ms-flex-direction: column-reverse;flex-direction: column-reverse;}
  .pwusConts .accordion{margin-bottom: 20px;}
  .kbtabBox .responsive-tabs-accordion{display: block;}
  .kbtabBox .accordion-tab {margin-bottom: 15px;}
  .kbtabBox .accordion-tab-title h4 {background: #f0e9d8;border: none;border-radius: 8px;padding: 10px 20px;margin: 0;text-align: center;cursor: pointer;color: #848383;font-family: 'halyard_textblack';font-size: 22px;letter-spacing: -0.5px;}
  .kbtabBox .active .accordion-tab-title h4 {background: #2b9fb7;border-bottom-left-radius: 0;border-bottom-right-radius: 0;color: #fff;}
  .kbtabBox .accordion-tab.active .accordion-tab-content{border: 1px solid #2b9fb7;}
  .kbtabBox .KBImg{margin-bottom: 20px;}
  .kbtabBox .KBCont h4{font-size: 6vw;}

}

@media screen and (max-width:420px) {
  .BanContSec .textBlack h1 {font-size: 34px;}
}

@media screen and (max-width:400px) {
  .OsoluIntroSec .tbTitle h2 {line-height: 29px; font-size: 34px;}
  .OsoluIntroSec:before { height: 32%;}
  .OSAccesibSec .tbTitle h2 span { font-size: 38px; letter-spacing: -1px;}
}